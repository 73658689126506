import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();
      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.successAction) {
        if (value.toastMessage) {
          yield toast.success(value.toastMessage);
        }
      }
      // if (value.fetchAction) {
      //   yield put({
      //     type: `${value.fetchAction}`,
      //     payload: resJSON,
      //   });
      // }
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
    return response;
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
    if (value.failAction) {
      if (value.toastErrorMessage) {
        yield toast.error(value.toastErrorMessage);
      }
    }
  }
}

function* addUser(value) {
  let fname = value.values.name;
  let lname = value.values.lName;

  let data = {
    name: value.values.name,
    lName: value.values.lName,
    fullName: `${fname} ${lname}`,
    mobileNo: value.values.mobileNo,
    email: value.values.email,
    orgName: value.values.orgName,
    password: value.values.password,
    country: value.values.country,
    curncyCode: value.values.curncyid,
    callingCode: value.values.callingCode,
  };

  try {
    let params = {
      api: `${appConfig.ip}/users`,
      method: "POST",
      successAction: actionTypes.ADD_USER_SUCCESS_ACTION,
      failAction: actionTypes.ADD_USER_FAIL_ACTION,
      extraAction: actionTypes.FETCH_USER_INIT_ACTION,
      body: JSON.stringify(data),
      toastMessage: "User added successfully",
      toastErrorMessage: "Sorry, email already exists. Try again",
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

function* fetchUser(value) {
  let searchVal =
    value && value.value && value.value.searchVal ? value.value.searchVal : "";

  try {
    let page = (value.value && value.value.page) || 1;
    let params = {
      page: page,
      api: `${appConfig.ip}/users?ser=${searchVal}&role=user&limit=10&page=${page}`,
      method: "GET",
      successAction: actionTypes.FETCH_USER_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_USER_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchUserId(value) {
  let id = value;
  try {
    let params = {
      api: `${appConfig.ip}/users/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_USERID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_USERID_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* editUser(value) {
  let fname = value.value.name;
  let lname = value.value.lName;

  let data = {
    name: value.value.name,
    lName: value.value.lName,
    fullName: `${fname} ${lname}`,
    // mobileNo: value.value.mobileNo,
    // email: value.value.email,
    orgName: value.value.orgName,
    country: value.value.country,
    curncyCode: value.value.curncyid,
    callingCode: value.value.callingCode,
    role: "user",
  };
  let id = value.item;

  try {
    let params = {
      api: `${appConfig.ip}/users/${id}`,
      method: "PUT",
      successAction: actionTypes.EDIT_USER_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_USER_FAIL_ACTION,
      // extraAction: actionTypes.FETCH_USER_INIT_ACTION,
      // fetchAction: actionTypes.FETCH_USERID_INIT_ACTION,
      body: JSON.stringify(data),
      toastMessage: "User details updated successfully",
      toastErrorMessage: "Sorry, email already exists. Try again",
    };

    let res = yield call(commonFunction, params);

    if (res) {
      yield call(fetchUserId, id);
    }
  } catch (error) {}
}

function* changeStatus(value) {
  let id = value.value.id;
  let data = {
    status: value.value.status === "active" ? "suspended" : "active",
  };

  try {
    let params = {
      api: `${appConfig.ip}/users/updateUser/${id}`,
      method: "PUT",
      successAction: actionTypes.CHANGE_STATUS_USER_SUCCESS_ACTION,
      failAction: actionTypes.CHANGE_STATUS_USER_FAIL_ACTION,
      // extraAction: actionTypes.FETCH_USER_INIT_ACTION,
      body: JSON.stringify(data),
    };
    let res = yield call(commonFunction, params);
    if (res) {
      yield call(fetchUserId, id);
    }
    yield toast.success("Status updated successfully", {
      autoClose: 3000,
    });
  } catch (err) {}
}

export function* UserActionWatcher() {
  yield takeEvery(actionTypes.ADD_USER_INIT_ACTION, addUser);
  yield takeEvery(actionTypes.FETCH_USER_INIT_ACTION, fetchUser);
  yield takeEvery(actionTypes.EDIT_USER_INIT_ACTION, editUser);
  yield takeEvery(actionTypes.CHANGE_STATUS_USER_INIT_ACTION, changeStatus);
  // yield takeEvery(actionTypes.FETCH_USERID_INIT_ACTION, fetchUserId);
}
