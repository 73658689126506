import * as action from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { appConfig } from "../../../config";
function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
  }
}

function* addMiscellaneousDefaultValue(values) {
  const data = {
    name: values.values.name,
    unit: values.values.unit,
    value: values.values.value,
    comments: values.values.comments,
  };

  try {
    let params = {
      api: `${appConfig.ip}/miscDefaultValue`,
      method: "POST",
      successAction: action.ADD_MISCELLANEOUS_SUCCESS_ACTION,
      failAction: action.ADD_MISCELLANEOUS_FAIL_ACTION,
      extraAction: action.FETCH_MISCELLANEOUS_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Miscellaneous default value added successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchMiscellaneousDefaultValue(value) {
  let searchVal =
    value && value.value && value.value.searchVal ? value.value.searchVal : "";
  try {
    let page = (value.value && value.value.page) || 1;
    let params = {
      page: page,
      api: `${appConfig.ip}/miscDefaultValue?ser=${searchVal}&isActive=true&limit=10&page=${page}`,
      method: "GET",
      successAction: action.FETCH_MISCELLANEOUS_SUCCESS_ACTION,
      failAction: action.FETCH_MISCELLANEOUS_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* editMiscellaneousDefaultValue(item) {
  const id = item.value.id;
  const data = item.item;
  try {
    let params = {
      api: `${appConfig.ip}/miscDefaultValue/${id}`,
      method: "PUT",
      successAction: action.EDIT_MISCELLANEOUS_SUCCESS_ACTION,
      failAction: action.EDIT_MISCELLANEOUS_FAIL_ACTION,
      extraAction: action.FETCH_MISCELLANEOUS_INIT_ACTION,
      body: JSON.stringify(data),
    };

    yield call(commonFunction, params);
    yield toast.success("Miscellaneous default value updated successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* deleteMiscellaneousDefaultValue(item) {
  let id = item.item.id;
  let data = {
    name: item.item.name,
    value: item.item.value,
    unit: item.item.unit,
    comments: item.item.comments,
    isActive: false,
  };
  try {
    let params = {
      api: `${appConfig.ip}/miscDefaultValue/${id}`,
      method: "PUT",
      successAction: action.DELETE_MISCELLANEOUS_SUCCESS_ACTION,
      failAction: action.DELETE_MISCELLANEOUS_FAIL_ACTION,
      extraAction: action.FETCH_MISCELLANEOUS_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
    yield toast.success("Miscellaneous default value deleted successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

export function* MiscellaneousDefaultValueActionWatcher() {
  yield takeEvery(
    action.ADD_MISCELLANEOUS_INIT_ACTION,
    addMiscellaneousDefaultValue
  );
  yield takeEvery(
    action.FETCH_MISCELLANEOUS_INIT_ACTION,
    fetchMiscellaneousDefaultValue
  );
  yield takeEvery(
    action.EDIT_MISCELLANEOUS_INIT_ACTION,
    editMiscellaneousDefaultValue
  );

  yield takeEvery(
    action.DELETE_MISCELLANEOUS_INIT_ACTION,
    deleteMiscellaneousDefaultValue
  );
}
