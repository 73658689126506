import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
  emissionFactorTypeList: [],
};

export default function EmissionFactorTypeReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_EMISSIONFACTORTYPE_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorTypeList: action.payload.rows,
        emissionFactorTypeCount: action.payload.count,
      };
    case actionTypes.FETCH_EMISSIONFACTORTYPEXSL_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorTypeXSLList: action.payload.rows,
      };
    case actionTypes.EDIT_EMISSIONFACTORTYPE_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorTypeList: state.emissionFactorTypeList.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        emissionFactorTypeCount: state.emissionFactorTypeCount,
      };

    default:
      return {
        ...state,
      };
  }
}
