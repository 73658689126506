export const FETCH_TRUCKDISTANCE_INIT_ACTION =
  "FETCH_TRUCKDISTANCE_INIT_ACTION";
export const FETCH_TRUCKDISTANCE_SUCCESS_ACTION =
  FETCH_TRUCKDISTANCE_INIT_ACTION + "_SUCCESS";
export const FETCH_TRUCKDISTANCE_FAIL_ACTION =
  FETCH_TRUCKDISTANCE_INIT_ACTION + "_ERROR";

export const ADD_TRUCKDISTANCE_INIT_ACTION = "ADD_TRUCKDISTANCE_INIT_ACTION";
export const ADD_TRUCKDISTANCE_SUCCESS_ACTION =
  ADD_TRUCKDISTANCE_INIT_ACTION + "_SUCCESS";
export const ADD_TRUCKDISTANCE_FAIL_ACTION =
  ADD_TRUCKDISTANCE_INIT_ACTION + "_ERROR";

export const EDIT_TRUCKDISTANCE_INIT_ACTION = "EDIT_TRUCKDISTANCE_INIT_ACTION";
export const EDIT_TRUCKDISTANCE_SUCCESS_ACTION =
  EDIT_TRUCKDISTANCE_INIT_ACTION + "_SUCCESS";
export const EDIT_TRUCKDISTANCE_FAIL_ACTION =
  EDIT_TRUCKDISTANCE_INIT_ACTION + "_ERROR";

export const DELETE_TRUCKDISTANCE_INIT_ACTION =
  "DELETE_TRUCKDISTANCE_INIT_ACTION";
export const DELETE_TRUCKDISTANCE_SUCCESS_ACTION =
  DELETE_TRUCKDISTANCE_INIT_ACTION + "_SUCCESS";
export const DELETE_TRUCKDISTANCE_FAIL_ACTION =
  DELETE_TRUCKDISTANCE_INIT_ACTION + "_ERROR";
