import React, { useState } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { TextField } from "../Common/TextField";
import * as Yup from "yup";
import { IconContext } from "react-icons";
import { BsCheck2Circle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
const ResetPassword = (props) => {
  const [passwordType1, setPasswordType1] = useState("password");
  const validate = Yup.object({
    oldPassword: Yup.string().required("This field is required"),
    newPassword: Yup.string()
      .required("This field is required")

      .when("oldPassword", (oldPassword) => {
        if (oldPassword) {
          return Yup.string()
            .min(6, "Password must be atleast 6 characters long")
            .notOneOf([Yup.ref("oldPassword")], "Please enter a new password")
            .required("This field is required");
        }
      }),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords does not match")
      .required("This field is required"),
  });

  // PASSWORD VISIBILITY

  // const togglePassword = () => {
  //   if (passwordType === "password") {
  //     setPasswordType("text");
  //     return;
  //   }
  //   setPasswordType("password");
  // };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={validate}
      onSubmit={(value) => {
        props.resetPassword(value);
      }}
    >
      {(formik) => (
        <div>
          <div className="userModalDiv">
            <Form>
              <div className="salesreturndiv mg-adjst">
                <div>
                  <Container>
                    <Row>
                      <Col xs={12} md={12} sm={12} className="main_colum">
                        <Card className="cardresetMain">
                          <Row className="resetheaderelmnts">
                            <Col xs={12} sm={12} md={6} lg={6}>
                              <div>
                                <h5 className="tablecardtitle resethead">
                                  Reset Password
                                </h5>
                              </div>
                            </Col>

                            <Col xs={12} sm={12} md={6} lg={6}>
                              <div className="editprofclose resetclose">
                                <Link
                                  to="/landingpage"
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  <IconContext.Provider
                                    value={{
                                      className:
                                        "global-class-name close-position-reset",
                                    }}
                                  >
                                    <BsXCircle />
                                  </IconContext.Provider>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <Card.Body>
                            <div>
                              <div className="loginreset_form">
                                <Row>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                      placeholder="Old password"
                                      name="oldPassword"
                                      type="password"
                                      errClassName="reset-err"
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <div style={{ display: "flex" }}>
                                      <TextField
                                        placeholder="New password"
                                        name="newPassword"
                                        type={passwordType1}
                                        errClassName="reset-err"
                                      />
                                      <span className="pswd-lgn">
                                        {passwordType1 === "password" ? (
                                          <i
                                            className="fa fa-eye-slash pswd-lgn-icon-reset"
                                            aria-hidden="true"
                                            onClick={togglePassword1}
                                          ></i>
                                        ) : (
                                          <i
                                            className="fa fa-eye pswd-lgn-icon-reset"
                                            aria-hidden="true"
                                            onClick={togglePassword1}
                                          ></i>
                                        )}
                                      </span>
                                    </div>
                                  </Col>

                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                      placeholder="Confirm password"
                                      name="confirmPassword"
                                      type="password"
                                      errClassName="reset-err"
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <div className="resetbtncentering">
                              {" "}
                              <button
                                className="btn btn-danger resetqueryBtn"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ResetPassword;
