import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
  emissionFactorSubTypeList: [],
};

export default function EmissionFactorSubTypeReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_EMISSIONFACTOR_SUBTYPE_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorSubTypeList: action.payload.rows,
        emissionFactorSubTypeCount: action.payload.count,
        emissionFactorSubtypeListxsl: [],
      };
    case actionTypes.FETCH_EMISSIONFACTOR_SUBTYPEXSL_SUCCESS_ACTION:
      return {
        ...state,

        emissionFactorSubtypeListxsl: action.payload.rows,
        emissionFactorSubtypeListxslCount: action.payload.count,
      };
    case actionTypes.EDIT_EMISSIONFACTOR_SUBTYPE_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorSubTypeList: state.emissionFactorSubTypeList.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        emissionFactorSubTypeCount: state.emissionFactorSubTypeCount,
      };
    // case actionTypes.DELETE_EMISSIONFACTOR_SUBTYPE_SUCCESS_ACTION:
    //   return {
    //     ...state,
    //     emissionFactorSubTypeList: state.emissionFactorSubTypeList.map((list) =>
    //       list.id === action.payload.id ? action.payload : list
    //     ),
    //     emissionFactorSubTypeCount: state.emissionFactorSubTypeCount,
    //   };
    default:
      return {
        ...state,
      };
  }
}
