import * as actionTypes from "./constant";
import * as actionType from "../../CreateProjectContainer/constant";
const initialState = {
  processing: false,
  error: false,
  seaDistanceList: [],
};

export default function SeaDistanceReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_SEADISTANCE_SUCCESS_ACTION:
      return {
        ...state,
        seaDistanceList: action.payload.rows,
        seaDistanceCount: action.payload.count,
      };
    case actionType.UPDATE_PROJECTS_DATA_INIT_ACTION:
      return {
        ...state,
        seaDistanceList: [],
      };

    default:
      return {
        ...state,
      };
  }
}
