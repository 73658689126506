export const FETCH_UNIT_INIT_ACTION = "FETCH_UNIT_INIT_ACTION";
export const FETCH_UNIT_SUCCESS_ACTION = FETCH_UNIT_INIT_ACTION + "_SUCCESS";
export const FETCH_UNIT_FAIL_ACTION = FETCH_UNIT_INIT_ACTION + "_ERROR";

export const ADD_UNIT_INIT_ACTION = "ADD_UNIT_INIT_ACTION";
export const ADD_UNIT_SUCCESS_ACTION = ADD_UNIT_INIT_ACTION + "_SUCCESS";
export const ADD_UNIT_FAIL_ACTION = ADD_UNIT_INIT_ACTION + "_ERROR";

export const EDIT_UNIT_INIT_ACTION = "EDIT_UNIT_INIT_ACTION";
export const EDIT_UNIT_SUCCESS_ACTION = EDIT_UNIT_INIT_ACTION + "_SUCCESS";
export const EDIT_UNIT_FAIL_ACTION = EDIT_UNIT_INIT_ACTION + "_ERROR";

export const DELETE_UNIT_INIT_ACTION = "DELETE_UNIT_INIT_ACTION";
export const DELETE_UNIT_SUCCESS_ACTION = DELETE_UNIT_INIT_ACTION + "_SUCCESS";
export const DELETE_UNIT_FAIL_ACTION = DELETE_UNIT_INIT_ACTION + "_ERROR";
