import * as action from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
  }
}

function* addSeaDistance(value) {
  const data = {
    meansOfTransport: value.value.meansOfTransport,
    origin: value.value.origin,
    destination: value.value.destination,
    desc: value.value.desc,
    distance: value.value.distance,
  };

  try {
    let params = {
      api: `${appConfig.ip}/mmtSeaDist`,
      method: "POST",
      successAction: action.ADD_SEADISTANCE_SUCCESS_ACTION,
      failAction: action.ADD_SEADISTANCE_FAIL_ACTION,
      extraAction: action.FETCH_SEADISTANCE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("SeaDistance type added successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

function* fetchSeaDistance(value) {
  try {
    let page = (value.value && value.value.page) || 1;
    let destination = (value.value && value.value.destination) || "";
    let searchVal = (value.value && value.value.searchVal) || "";

    let params = {
      page: page,
      api: `${appConfig.ip}/mmtSeaDist?isActive=true&limit=10&page=${page}&destination=${destination}&ser=${searchVal}`,
      method: "GET",
      successAction: action.FETCH_SEADISTANCE_SUCCESS_ACTION,
      failAction: action.FETCH_SEADISTANCE_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* editSeaDistance(value) {
  const data = value.value;
  const id = value.item.id;
  try {
    let params = {
      api: `${appConfig.ip}/mmtSeaDist/${id}`,
      method: "PUT",
      successAction: action.EDIT_SEADISTANCE_SUCCESS_ACTION,
      failAction: action.EDIT_SEADISTANCE_FAIL_ACTION,
      extraAction: action.FETCH_SEADISTANCE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* deleteSeaDistance(item) {
  const data = {
    meansOfTransport: item.item.meansOfTransport,
    origin: item.item.origin,
    destination: item.item.destination,
    desc: item.item.desc,
    distance: item.item.distance,
    isActive: false,
  };
  const id = item.item.id;
  try {
    let params = {
      api: `${appConfig.ip}/mmtSeaDist/${id}`,
      method: "PUT",
      successAction: action.EDIT_SEADISTANCE_SUCCESS_ACTION,
      failAction: action.EDIT_SEADISTANCE_FAIL_ACTION,
      extraAction: action.FETCH_SEADISTANCE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

export function* SeaDistanceActionWatcher() {
  yield takeEvery(action.ADD_SEADISTANCE_INIT_ACTION, addSeaDistance);
  yield takeEvery(action.FETCH_SEADISTANCE_INIT_ACTION, fetchSeaDistance);
  yield takeEvery(action.EDIT_SEADISTANCE_INIT_ACTION, editSeaDistance);
  yield takeEvery(action.DELETE_SEADISTANCE_INIT_ACTION, deleteSeaDistance);
}
