import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value, toastSuccess = false, toastError = true) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Bad Request - Field must not be empty";
    } else if (error.status === 401) {
      message = "Undefined";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status === 404) {
      message = "Not Found";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(`Error: ${message}`, { autoClose: 3000 });
    }

    throw error;
  }
}

function* fetchCountry(value) {
  try {
    let page = (value.value && value.value.page) || 1;
    let searchVal = (value?.value?.searchVal && value?.value?.searchVal) || "";
    let limit = (value?.value?.limit && value?.value?.limit) || 10;
    let params = {
      page: page,
      api: `${appConfig.ip}/country?isActive=true&limit=${limit}&page=${page}&q=${searchVal}`,
      method: "GET",
      successAction: actionTypes.FETCH_COUNTRY_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_COUNTRY_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* addCountry(value) {
  const data = {
    name: value.value.name,
    isoName: value.value.isoName,
    iso3Name: value.value.iso3Name,
    niceName: value.value.niceName,
    callingCode: value.value.callingCode,
    ifiMethodology: value.value.ifiMethodology,
    truckFreight: value.value.truckFreight,
  };

  try {
    let params = {
      api: `${appConfig.ip}/country`,
      method: "POST",
      successAction: actionTypes.ADD_COUNTRY_SUCCESS_ACTION,
      failAction: actionTypes.ADD_COUNTRY_FAIL_ACTION,
      extraAction: actionTypes.FETCH_COUNTRY_INIT_ACTION,

      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);

    yield toast.success("Country added successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* editCountry(value) {
  const data = value.value;
  const id = value.item.id;
  try {
    let params = {
      api: `${appConfig.ip}/country/${id}`,
      method: "PUT",
      successAction: actionTypes.EDIT_COUNTRY_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_COUNTRY_FAIL_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Country updated successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* deleteCountry(value) {
  const id = value.value.id;
  const data = {
    name: value.value.name,
    isoName: value.value.isoName,
    iso3Name: value.value.iso3Name,
    niceName: value.value.niceName,
    callingCode: value.value.callingCode,
    isActive: false,
  };

  try {
    let params = {
      api: `${appConfig.ip}/country/${id}`,
      method: "PUT",
      successAction: actionTypes.DELETE_COUNTRY_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_COUNTRY_FAIL_ACTION,
      extraAction: actionTypes.FETCH_COUNTRY_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Country deleted successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

export function* CountryActionWatcher() {
  yield takeEvery(actionTypes.FETCH_COUNTRY_INIT_ACTION, fetchCountry);
  yield takeEvery(actionTypes.ADD_COUNTRY_INIT_ACTION, addCountry);
  yield takeEvery(actionTypes.EDIT_COUNTRY_INIT_ACTION, editCountry);
  yield takeEvery(actionTypes.DELETE_COUNTRY_INIT_ACTION, deleteCountry);
}
