export const FORGOT_SEND_OTP_PASSWORD_INIT_ACTION =
  "FORGOT_SEND_OTP_PASSWORD_INIT_ACTION";
export const FORGOT_SEND_OTP_PASSWORD_SUCCESS_ACTION =
  FORGOT_SEND_OTP_PASSWORD_INIT_ACTION + "_SUCCESS";
export const FORGOT_SEND_OTP_PASSWORD_FAIL_ACTION =
  FORGOT_SEND_OTP_PASSWORD_INIT_ACTION + "_ERROR";

export const FORGOT_RESEND_OTP_PASSWORD_INIT_ACTION =
  "FORGOT_RESEND_OTP_PASSWORD_INIT_ACTION";
export const FORGOT_RESEND_OTP_PASSWORD_SUCCESS_ACTION =
  FORGOT_RESEND_OTP_PASSWORD_INIT_ACTION + "_SUCCESS";
export const FORGOT_RESEND_OTP_PASSWORD_FAIL_ACTION =
  FORGOT_RESEND_OTP_PASSWORD_INIT_ACTION + "_ERROR";

export const OTP_VERIFICATION_INIT_ACTION = "OTP_VERIFICATION_INIT_ACTION";
export const OTP_VERIFICATION_SUCCESS_ACTION =
  OTP_VERIFICATION_INIT_ACTION + "_SUCCESS";
export const OTP_VERIFICATION_FAIL_ACTION =
  OTP_VERIFICATION_INIT_ACTION + "_ERROR";

export const REDIRECT_TO_LOGIN_INIT_ACTION = "REDIRECT_TO_LOGIN_INIT_ACTION";
export const REDIRECT_TO_LOGIN_SUCCESS_ACTION =
  REDIRECT_TO_LOGIN_INIT_ACTION + "_SUCCESS";
export const REDIRECT_TO_LOGIN_FAIL_ACTION =
  REDIRECT_TO_LOGIN_INIT_ACTION + "_ERROR";

export const RESET_NEW_PASSWORD_INIT_ACTION = "RESET_NEW_PASSWORD_INIT_ACTION";
export const RESET_NEW_PASSWORD_SUCCESS_ACTION =
  RESET_NEW_PASSWORD_INIT_ACTION + "_SUCCESS";
export const RESET_NEW_PASSWORD_FAIL_ACTION =
  RESET_NEW_PASSWORD_INIT_ACTION + "_ERROR";
