import React from "react";

const Dashboard = React.lazy(() => import("./containers/DashboardContainer"));
const Login = React.lazy(() => import("./containers/LoginContainer"));

const ForgotPassword = React.lazy(() =>
  import("./containers/ForgotPasswordContainer")
);

const CreateProject = React.lazy(() =>
  import("./containers/CreateProjectContainer")
);

const LandingPage = React.lazy(() =>
  import("./containers/LandingPageContainer")
);
const ResetPassword = React.lazy(() =>
  import("./containers/ResetPasswordContainer")
);
const TermsCondition = React.lazy(() => import("./screens/termcondition"));

const CalculationPage = React.lazy(() =>
  import("./containers/CalculationPageContainer")
);

const Report = React.lazy(() => import("./containers/ReportContainer"));

const ProjectView = React.lazy(() =>
  import("./containers/ProjectViewContainer")
);

const Country = React.lazy(() =>
  import("./containers/AdminContainer/CountryContainer")
);
const Currency = React.lazy(() =>
  import("./containers/AdminContainer/CurrencyContainer")
);
const User = React.lazy(() =>
  import("./containers/AdminContainer/UserContainer")
);
const CountryCurrency = React.lazy(() =>
  import("./containers/AdminContainer/CountryCurrencyContainer")
);
const ProjectType = React.lazy(() =>
  import("./containers/AdminContainer/ProjectTypeContainer")
);
const DevelopmentStage = React.lazy(() =>
  import("./containers/AdminContainer/DevelopmentStageContainer")
);

const Unit = React.lazy(() =>
  import("./containers/AdminContainer/UnitContainer")
);

const EmissnFactorType = React.lazy(() =>
  import("./containers/AdminContainer/EmissnFactorTypeContainer")
);
const EmissionFactorSubtype = React.lazy(() =>
  import("./containers/AdminContainer/EmissionFactorSubtypeContainer")
);

const Miscellaneous = React.lazy(() =>
  import("./containers/AdminContainer/MiscellaneousContainer")
);

const EmissionFactorSession2 = React.lazy(() =>
  import("./containers/AdminContainer/EmissionFactorSession2Container")
);

const EmissionFactorSession = React.lazy(() =>
  import("./containers/AdminContainer/EmissionFactorSessionContainer")
);

const InterventionType = React.lazy(() =>
  import("./containers/AdminContainer/InterventionTypeContainer")
);
const MyProfile = React.lazy(() => import("./screens/MyProfile"));

const HelpPage = React.lazy(() => import("./containers/HelpPageContainer"));

const SummeryPage = React.lazy(() =>
  import("./containers/SummeryPageContainer")
);

const Notifications = React.lazy(() =>
  import("./containers/NotificationContainer")
);

const MsWord = React.lazy(() =>
  import("./containers/AdminContainer/MsWordContainer")
);

const EFLOG = React.lazy(() =>
  import("./containers/AdminContainer/AuditTrailContainer")
);
const HarmonisedBaseline = React.lazy(() =>
  import("./containers/AdminContainer/HarmonisedBaselineContainer")
);

const SmkTruckDist = React.lazy(() =>
  import("./containers/AdminContainer/SmkTruckDistContainer")
);

const MmtSeaDist = React.lazy(() =>
  import("./containers/AdminContainer/SeaDistanceContainer")
);

const LandDistance = React.lazy(() =>
  import("./containers/AdminContainer/LandDistanceContainer")
);
const C4Ratio = React.lazy(() => import("./containers/AdminContainer/C4Ratio"));
const CO2Simulator=React.lazy(() => import("./containers/AdminContainer/CO2SimulatorContainer"));
const routes = [
  {
    path: "/landingpage/:id/summary",
    name: "SummeryPage",
    component: SummeryPage,
  },
  {
    path: "/landingpage/:id",
    name: "Project Details",
    component: ProjectView,
    exact: true,
  },
  {
    path: "/landingpage",
    name: "My Project",
    component: LandingPage,
  },
  { path: "/login", name: "Login", component: Login },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/createproject/:id/calculation",
    name: "Calculation",
    component: CalculationPage,
  },
  {
    path: "/createproject/:id/report",
    name: "Report",
    component: Report,
  },
  {
    path: "/createproject/:id/summary",
    name: "SummeryPage",
    component: SummeryPage,
  },

  {
    path: "/createproject/:id",
    name: "Create Project",
    component: CreateProject,
  },
  {
    path: "/createproject",
    name: "Create Project",
    component: CreateProject,
  },
  {
    path: "/help",
    name: "Help",
    component: HelpPage,
  },
  { path: "/myprofile", name: "My Profile", component: MyProfile },

  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/notification",
    name: "Notification",
    component: Notifications,
  },
  {
    path: "/termsCondition",
    name: "TermsCondition",
    component: TermsCondition,
  },

  {
    path: "/masterdata/country",
    name: "Country",
    component: Country,
  },
  {
    path: "/masterdata/currency",
    name: "Currency",
    component: Currency,
  },
  {
    path: "/masterdata/users",
    name: "User",
    component: User,
  },
  {
    path: "/masterdata/Countrycurrency",
    name: "Country Currency",
    component: CountryCurrency,
  },
  {
    path: "/masterdata/projecttype",
    name: "Project Type",
    component: ProjectType,
  },
  {
    path: "/masterdata/developmentstage",
    name: "Project Development Stage",
    component: DevelopmentStage,
  },

  {
    path: "/masterdata/InterventionType",
    name: "Type Of Intervention ",
    component: InterventionType,
  },

  {
    path: "/masterdata/Unit",
    name: "Unit",
    component: Unit,
  },
  {
    path: "/masterdata/EmissnFactorType",
    name: "Emission Factor Type",
    component: EmissnFactorType,
  },

  {
    path: "/masterdata/EmissionFactorSubtype",
    name: "Emission Factor Subtype",
    component: EmissionFactorSubtype,
  },
  {
    path: "/masterdata/miscelvalue",
    name: "Miscellaneous Default Value",
    component: Miscellaneous,
  },

  {
    path: "/masterdata/EFSession2",
    name: "Emission Factor Session 2",
    component: EmissionFactorSession2,
  },
  {
    path: "/masterdata/EmissionFactorSession",
    name: "Emission Factor Session",
    component: EmissionFactorSession,
  },
  {
    path: "/masterdata/MsWord",
    name: "Ms Word",
    component: MsWord,
  },
  {
    path: "/masterdata/EFLOG",
    name: "Activity Log",
    component: EFLOG,
  },
  {
    path: "/masterdata/harmonisedBaseline",
    name: "Harmonised Baseline",
    component: HarmonisedBaseline,
  },

  {
    path: "/masterdata/seadistance",
    name: "Sea Distance",
    component: MmtSeaDist,
  },
  {
    path: "/masterdata/landdistance",
    name: "Land Distance",
    component: LandDistance,
  },
  {
    path: "/masterdata/truckdistance",
    name: "Truck Distance",
    component: SmkTruckDist,
  },
  {
    path: "/masterdata/C4Ratios",
    name: "C4 Ratios",
    component: C4Ratio,
  },
  {
    path:'/masterdata/CO2 Simulator',
    name: "CO2 Simulator",
    component: CO2Simulator,
  }
];

export default routes;
