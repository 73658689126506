import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
  emissionFactorByNoList: [],
};

export default function CreateProjectReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_PROJECT_DATA_INIT_ACTION:
      return {
        ...state,
        generalInfoList: action.value,
      };
    case actionTypes.DOWNLOAD_REPORT_INIT_ACTION:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.DOWNLOAD_REPORT_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
      };
    case actionTypes.DOWNLOAD_REPORT_FAIL_ACTION:
      return {
        ...state,
        processing: false,
      };

    case actionTypes.FETCH_EF_BY_NO_INIT_ACTION:
      return {
        ...state,
        emissionFactorByNoList: [],
      };
    case actionTypes.FETCH_EF_BY_NO_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorByNoList: action.payload.rows,
      };
    case actionTypes.REMOVE_EF_BY_NO:
      return {
        ...state,
        emissionFactorByNoList: [],
      };
    case actionTypes.FETCH_REVISIONLIST_SUCCESS_ACTION:
      return {
        ...state,
        revisionList: action.payload.rows,
        revisionCount: action.payload.count,
      };
    case actionTypes.DOWNLOAD_REVISION_REPORT_INIT_ACTION:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.DOWNLOAD_REVISION_REPORT_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
      };
    case actionTypes.DOWNLOAD_REVISION_REPORT_FAIL_ACTION:
      return {
        ...state,
        processing: false,
      };
    default:
      return {
        ...state,
      };
  }
}
