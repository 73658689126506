import * as actionTypes from "./constant";
const addGeneralInformation = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
      value: value,
    });
  };
};

const addProject = (value, generalInfoList, projectSegments) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PROJECTS_INIT_ACTION,
      value: value,
      generalInfoList: generalInfoList,
      projectSegment: projectSegments,
    });
  };
};

const updateProjectData = (projectData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_PROJECTS_DATA_INIT_ACTION,
      projectData: projectData,
    });
  };
};

const fetchProjectById = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_PROJECTBYID_INIT_ACTION,
      value: value,
    });
  };
};

const downloadReport = (value, projectId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DOWNLOAD_REPORT_INIT_ACTION,
      value: value,
      projectId: projectId,
    });
  };
};
const downloadRevisionReport = (value, projectId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DOWNLOAD_REVISION_REPORT_INIT_ACTION,
      value: value,
      projectId: projectId,
    });
  };
};
const fetchEmissionFactorByNo = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_EF_BY_NO_INIT_ACTION,
      value: value,
    });
  };
};

const removeEmissionFactoByNo = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_EF_BY_NO,
    });
  };
};
const fetchRevisionById = (item) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_REVISIONBYID_INIT_ACTION,
      value: item,
    });
  };
};

const addRevision = (item, value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_REVISION_INIT_ACTION,
      revisionData: item,
    });
  };
};
const fetchRevisionList = (item) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_REVISIONLIST_INIT_ACTION,
      item: item,
    });
  };
};
export default {
  addGeneralInformation,
  addProject,
  updateProjectData,
  fetchProjectById,
  downloadReport,
  downloadRevisionReport,
  fetchEmissionFactorByNo,
  removeEmissionFactoByNo,
  fetchRevisionById,
  addRevision,
  fetchRevisionList,
};
