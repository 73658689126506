import * as React from "react";
import { connect } from "react-redux";

import { logOut } from "../../LoginContainer/actions";
import Promptmsg from "../../../screens/Settings/SuspendPrompt/promptmsg";

class PromptMsgContainer extends React.Component<Props, State> {
  signOut = (e) => {
    e.preventDefault();
    this.props.logout();
    this.props.history.push("/login");
  };

  render() {
    return <Promptmsg logoutaction={(e) => this.signOut(e)} />;
  }
}
function bindAction(dispatch) {
  return {
    logout: () => {
      dispatch(logOut());
      //alert("heello");
    },
  };
}
const mapStateToProps = (state) => {
  return {
    //states:state.UpdateProfileReducer.states,
  };
};

export default connect(mapStateToProps, bindAction)(PromptMsgContainer);
