import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { node } from "prop-types";

class Promptmsg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  render() {
    return (
      <div>
        <Modal
          show={this.state.showModal}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <h4 style={{ fontWeight: "bold", color: "#06851d" }}>
              Sorry, your profile is temporarily suspended. Please contact our
              support executive for further details
            </h4>
            <Button
              style={{
                backgroundColor: "#06851d",
                borderColor: "#06851d",
                borderRadius: "30px",
                width: "97px",
              }}
              onClick={(e) => this.props.logoutaction(e)}
            >
              Log Out
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Promptmsg;
