import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
  emissionFactorSubType: [],
  EFSession2List: [],
  EFSession2List: [],
  page: 1,
  limit: 10,
};

export default function EFSession2Reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_EFSESSION2_SUCCESS_ACTION:
      return {
        ...state,
        EFSession2List: action.payload.rows,
        EFSessionCount: action.payload.count,
        EFSession2xslList: [],
      };

    case actionTypes.FETCH_EFSESSION2XSL_SUCCESS_ACTION:
      return {
        ...state,
        EFSession2xslList: action.payload.rows,
      };
    case actionTypes.UPDATE_EFSESSION2_ACTION:
      return {
        ...state,
        EFSession2List: [],
        EFSessionCount: 0,
      };

    case actionTypes.FETCH_SUBTYPEEMISSIONFACTOR_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorSubType: action.payload.rows,
        emissionFactorSubTypeCount: action.payload.count,
      };
    case actionTypes.UPDATE_EFSUBTYPE_ACTION:
      return {
        ...state,
        emissionFactorSubType: [],
        emissionFactorSubTypeCount: 0,
      };

    case actionTypes.EDIT_EFSESSION2_SUCCESS_ACTION:
      return {
        ...state,
        EFSession2List: state.EFSession2List.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        EFSessionCount: state.EFSessionCount,
      };
    // case actionTypes.DELETE_EFSESSION2_SUCCESS_ACTION:
    //   return {
    //     ...state,
    //     EFSession2List: state.EFSession2List.map((list) =>
    //       list.id === action.payload.id ? action.payload : list
    //     ),
    //     EFSessionCount: state.EFSessionCount,
    //   };

    default:
      return {
        ...state,
      };
  }
}
