import * as actionTypes from "./constants";
const initialState = {
  processing: false,
  error: false,
  landDistanceList: [],
};

export default function LandDistanceReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_LANDDISTANCE_SUCCESS_ACTION:
      return {
        ...state,
        landDistanceList: action.payload.rows,
        landDistanceCount: action.payload.count,
      };
    default:
      return {
        ...state,
      };
  }
}
