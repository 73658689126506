export const FETCH_HARMONISEDBASELINE_INIT_ACTION =
  "FETCH_HARMONISEDBASELINE_INIT_ACTION";
export const FETCH_HARMONISEDBASELINE_SUCCESS_ACTION =
  FETCH_HARMONISEDBASELINE_INIT_ACTION + "_SUCCESS";
export const FETCH_HARMONISEDBASELINE_FAIL_ACTION =
  FETCH_HARMONISEDBASELINE_INIT_ACTION + "_ERROR";

export const ADD_HARMONISEDBASELINE_INIT_ACTION =
  "ADD_HARMONISEDBASELINE_INIT_ACTION";
export const ADD_HARMONISEDBASELINE_SUCCESS_ACTION =
  ADD_HARMONISEDBASELINE_INIT_ACTION + "_SUCCESS";
export const ADD_HARMONISEDBASELINE_FAIL_ACTION =
  ADD_HARMONISEDBASELINE_INIT_ACTION + "_ERROR";

export const EDIT_HARMONISEDBASELINE_INIT_ACTION =
  "EDIT_HARMONISEDBASELINE_INIT_ACTION";
export const EDIT_HARMONISEDBASELINE_SUCCESS_ACTION =
  EDIT_HARMONISEDBASELINE_INIT_ACTION + "_SUCCESS";
export const EDIT_HARMONISEDBASELINE_FAIL_ACTION =
  EDIT_HARMONISEDBASELINE_INIT_ACTION + "_ERROR";

export const DELETE_HARMONISEDBASELINE_INIT_ACTION =
  "DELETE_HARMONISEDBASELINE_INIT_ACTION";
export const DELETE_HARMONISEDBASELINE_SUCCESS_ACTION =
  DELETE_HARMONISEDBASELINE_INIT_ACTION + "_SUCCESS";
export const DELETE_HARMONISEDBASELINE_FAIL_ACTION =
  DELETE_HARMONISEDBASELINE_INIT_ACTION + "_ERROR";
