import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as router from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { logOut } from "../../containers/LoginContainer/actions";
import { pusher } from "../../service/pusher";

import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";

import { navLicensee } from "../../_nav";
import routes from "../../routes";
import { fetchGetEquation } from "../../containers/DefaultLayout/action";
import Action from "../CreateProjectContainer/action";
import { unReadNotification } from "../../containers/NotificationContainer/action";

const DefaultAside = React.lazy(() => import("./DefaultAside"));
const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

// class DefaultLayout extends Component {
function DefaultLayout(props) {
  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  const signOut = (e) => {
    let user = JSON.parse(localStorage.getItem("PsbUser"));
    pusher.unsubscribe(user.id);
    e.preventDefault();
    props.logoutaction();
  };

  useEffect(() => {
    let userDetails = JSON.parse(localStorage.getItem("PsbUser"));
    pusherfunction(userDetails);
    props.fetchGetEquation();
    props.unReadNotification({});
    return () => (userDetails ? pusher.unsubscribe(userDetails.id) : "");
  }, []);

  const pusherfunction = (user) => {
    if (user) {
      const pusherchannel = pusher.subscribe(user && user.id);
      pusherchannel.bind("shareProject", function (data) {
        let value = { fromPusher: true, data: data };
        props.unReadNotification(value);
      });
    }
  };

  // componentWillUnmount() {
  //   let userDetails = JSON.parse(localStorage.getItem("PsbUser"));
  //   if (userDetails) {
  //     pusher.unsubscribe(userDetails.id);
  //   }
  // }

  // render() {
  let user = JSON.parse(localStorage.getItem("PsbUser"));
  return (
    <div className="app">
      <AppHeader fixed>
        <Suspense fallback={loading()}>
          <DefaultHeader
            onLogout={(e) => signOut(e)}
            location={props.location}
            addGeneralInformation={props.addGeneralInformation}
            unReadNotifcationCount={props.unReadNotifcationCount}
            unReadNotification={props.unReadNotification}
          />
        </Suspense>
      </AppHeader>
      <div className="app-body">
        {user &&
          user.role === "admin" &&
          props.location.pathname.includes("masterdata") && (
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              <Suspense>
                <AppSidebarNav
                  navConfig={navLicensee}
                  {...props}
                  router={router}
                />
              </Suspense>
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
          )}
        <main className="main">
          <Container fluid>
            <Suspense fallback={loading()}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) =>
                        localStorage.getItem("Psbtoken") != null ? (
                          <route.component {...props} />
                        ) : (
                          <Redirect from="/" to="/login" />
                        )
                      }
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/landingpage" />
              </Switch>
            </Suspense>
          </Container>
        </main>
        <AppAside fixed>
          <Suspense fallback={loading()}>
            <DefaultAside />
          </Suspense>
        </AppAside>
      </div>
      {/* <AppFooter>
          <Suspense fallback={loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter> */}
    </div>
  );
  // }
}

function bindAction(dispatch) {
  return {
    logoutaction: () => {
      dispatch(logOut());
    },
    fetchGetEquation: () => {
      dispatch(fetchGetEquation());
    },
    addGeneralInformation: () => {
      dispatch(Action.addGeneralInformation({}));
    },
    unReadNotification: (value) => {
      dispatch(unReadNotification(value));
    },
  };
}

function mapStateToProps(state) {
  return {
    unReadNotifcationCount: state.NotificationReducer.unReadNotifcationCount,
  };
}

export default connect(mapStateToProps, bindAction)(DefaultLayout);
