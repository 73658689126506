import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// function* commonFunction(value) {
//   const Psbtoken = localStorage.getItem("Psbtoken");
//   try {
//     const response = yield fetch(`${value.api}`, {
//       method: `${value.method}`,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${Psbtoken}`,
//       },
//       body: value.body ? value.body : null,
//     });
//     if (!response.ok) {
//       throw response;
//     } else {
//       const resJSON = yield response.json();

//       yield put({
//         type: `${value.successAction}`,
//         payload: resJSON,
//       });
//       if (value.extraAction) {
//         yield put({
//           type: `${value.extraAction && value.extraAction}`,
//           payload: resJSON,
//         });
//       }
//     }
//   } catch (error) {
//     console.error("error", error);
//     yield put({ type: `${value.failAction}`, error: error });
//   }
// }

function* commonFunction(value, toastSuccess = false, toastError = true) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Bad Request - Field must not be empty";
    } else if (error.status === 401) {
      message = "Undefined";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status === 404) {
      message = "Not Found";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(`Error: ${message}`, { autoClose: 3000 });
    }

    throw error;
  }
}

function* addIntervensionType(value) {
  const data = value.value;
  try {
    let params = {
      api: `${appConfig.ip}/interventnType`,
      method: "POST",
      successAction: actionTypes.ADD_INTERVENTIONTYPE_SUCCESS_ACTION,
      failAction: actionTypes.ADD_INTERVENTIONTYPE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_INTERVENTIONTYPE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Intervension type added successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* fetchIntervensionType(value) {
  let searchVal =
    value && value.value && value.value.searchVal ? value.value.searchVal : "";
  try {
    let page = (value.value && value.value.page) || 1;

    let params = {
      page: page,
      api: `${appConfig.ip}/interventnType?ser=${searchVal}&isActive=true&limit=10&page=${page}`,
      method: "GET",
      successAction: actionTypes.FETCH_INTERVENTIONTYPE_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_INTERVENTIONTYPE_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* deleteInterventionType(item) {
  const id = item.item.id;
  const data = {
    name: item.item.name,
    desc: item.item.desc,
    isActive: false,
  };
  try {
    let params = {
      api: `${appConfig.ip}/interventnType/${id}`,
      method: "PUT",
      successAction: actionTypes.DELETE_INTERVENTIONTYPE_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_INTERVENTIONTYPE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_INTERVENTIONTYPE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Intervention type deleted successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* editIntervensionType(value) {
  const id = value.item.id;
  const data = value.value;
  try {
    let params = {
      api: `${appConfig.ip}/interventnType/${id}`,
      method: "PUT",
      successAction: actionTypes.EDIT_INTERVENTIONTYPE_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_INTERVENTIONTYPE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_INTERVENTIONTYPE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Intervention type updated successfully", {
      autoClose: 3000,
    });
  } catch (err) {}
}

export function* InterventionTypeActionWatcher() {
  yield takeEvery(
    actionTypes.ADD_INTERVENTIONTYPE_INIT_ACTION,
    addIntervensionType
  );

  yield takeEvery(
    actionTypes.FETCH_INTERVENTIONTYPE_INIT_ACTION,
    fetchIntervensionType
  );
  yield takeEvery(
    actionTypes.DELETE_INTERVENTIONTYPE_INIT_ACTION,
    deleteInterventionType
  );
  yield takeEvery(
    actionTypes.EDIT_INTERVENTIONTYPE_INIT_ACTION,
    editIntervensionType
  );
}
