import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
};

export default function ProjectTypeReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTTYPE_SUCCESS_ACTION:
      return {
        ...state,
        projectTypeList: action.payload.rows,
        projectTypeCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
