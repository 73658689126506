import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "./cmnStyle.css";

function CardHead(props) {
  const {
    filterData = [],
    filterHandler,
    filter,
    filtertitle,
    hasFilter = false,
    showFilterIcon = false,
    hasSearch = false,
    placeholder,
    searchHandler,
    value,
    custFilterData = [],
    routeFilterData = [],
    secfilterHandler,
    sechasFilter = false,
    secfilterData = [],
    secfilter,
    secfiltertitle,
    thirdfilterHandler,
    thirdhasFilter = false,
    thirdfilterData = [],
    thirdfilter,
    thirdfiltertitle,
  } = props;

  if (filterData) {
    var filterArrData = filterData
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((x) => ({
        ...x,
        value: x.id,
        label: x.name,
      }));
    filterArrData.unshift({ value: "", label: "All" });
  }

  if (custFilterData) {
    var filterCustArrData = custFilterData
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((x) => ({
        ...x,
        value: x.id || x.value,
        label: x.name,
        //value: x.id, label: x.name
      }));
    filterCustArrData.unshift({ value: "", label: "All" });
  }

  if (routeFilterData) {
    var filterRoutrArrData = routeFilterData
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((x) => ({
        ...x,
        value: x.id || x.value,
        label: x.name,
        //value: x.id, label: x.name
      }));
    filterRoutrArrData.unshift({ value: "", label: "All" });
  }

  const ShowAndHide = () => {
    let x = document.getElementById("ShowSection");
    if (x.style.display === "none") {
      x.style.display = "block";
      if (props.filterClicked === false) {
        props.setilterClicked(true);
      }
    } else {
      x.style.display = "none";
      if (props.filterClicked === true) {
        props.setilterClicked(false);
      }
    }
    // document.getElementById("search").innerHTML = "";
  };

  return (
    <div>
      <div className="cmnsearchfilter searchinsm">
        {hasSearch && (
          <div
            className="commonInput searchinsmwidth"
            style={{ flexGrow: "1" }}
          >
            <i className="fa fa-search fa-lg cmnSearchIcon"></i>
            <input
              id="search"
              type="text"
              placeholder={placeholder}
              value={value}
              onChange={(e) => searchHandler(e)}
            />
          </div>
        )}
        {!showFilterIcon && (
          <div style={{ flexGrow: "2" }} className=" filterinsm"></div>
        )}
        {showFilterIcon && (
          <div style={{ flexGrow: "2" }} className=" filterinsm">
            <i
              className="fa fa-filter cmnfilter"
              aria-hidden="true"
              onClick={() => ShowAndHide()}
            ></i>
          </div>
        )}
      </div>

      <div id="ShowSection" style={{ display: "none" }}>
        <Card style={{ marginTop: "20px" }} className="filtercard">
          <Card.Body className="cardbdyfilter">
            <Row style={{ marginTop: "10px" }}>
              {hasFilter && (
                <Col xs={8} sm={12} md={4} lg={4}>
                  <div className="commonFilterDiv">
                    <p className="filtertitle">{filtertitle}</p>
                    <select
                      id="filterID"
                      value={filter}
                      onChange={(e) => filterHandler(e)}
                      className="commonFilterSelect"
                    >
                      {filterData &&
                        filterData.length &&
                        filterData.map((fil, i) => {
                          return (
                            <option key={i} value={fil.value}>
                              {fil.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </Col>
              )}
              {sechasFilter && (
                <Col xs={8} sm={12} md={4} lg={4}>
                  <div className="commonFilterDiv">
                    <p className="filtertitle"> {secfiltertitle}</p>
                    <select
                      id="secFilterID"
                      value={secfilter}
                      onChange={(e) => secfilterHandler(e)}
                      className="commonFilterSelect"
                    >
                      {secfilterData &&
                        secfilterData.length &&
                        secfilterData.map((fil, i) => {
                          return (
                            <option key={i} value={fil.value}>
                              {fil.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </Col>
              )}

              {thirdhasFilter && (
                <Col xs={8} sm={12} md={4} lg={4}>
                  <div className="commonFilterDiv">
                    <p className="filtertitle"> {thirdfiltertitle}</p>
                    <select
                      id="thirdFilterID"
                      value={thirdfilter}
                      onChange={(e) => thirdfilterHandler(e)}
                      className="commonFilterSelect"
                    >
                      {thirdfilterData &&
                        thirdfilterData.length &&
                        thirdfilterData.map((fil, i) => {
                          return (
                            <option key={i} value={fil.value}>
                              {fil.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default withRouter(CardHead);
