import { put, takeEvery, call } from "redux-saga/effects";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BroadcastChannel } from "broadcast-channel";
import {
  LOGIN_INIT_ACTION,
  FETCH_LOGIN_FAIL_ACTION,
  FETCH_LOGIN_SUCCESS_ACTION,
  LOGOUT_ACTION,
} from "./actions";
import { appConfig } from "../../config";
import { push } from "connected-react-router";
var btoa = require("Base64").btoa;
const logoutChannel = new BroadcastChannel("token");

function* login(cred) {
  try {
    const loginData = {
      username: cred.cred.username,
      password: cred.cred.password,
    };

    const res = yield fetch(appConfig.ip + "/auth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + btoa(loginData.username + ":" + loginData.password),
      },
    });

    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({
        type: FETCH_LOGIN_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield localStorage.setItem("Psbtoken", resJSON.token);
      yield localStorage.setItem("PsbUserRole", resJSON.user.role);
      yield localStorage.setItem("PsbUser", JSON.stringify(resJSON.user));

      if (
        resJSON.user.status === "deleted" ||
        resJSON.user.status === "suspended"
      ) {
        yield put(push("/promptMsg"));
      } else if (
        resJSON.user.role === "user" ||
        resJSON.user.role === "admin"
      ) {
        yield put(push("/landingpage"));
        yield toast.success("Login successful", {
          autoClose: 3000,
        });
      } else {
      }
    }
  } catch (err) {
    if (err.status === 401) {
      yield toast.error("Invalid credentials", {
        autoClose: 3000,
        position: "top-center",
      });
    }

    yield put({
      type: FETCH_LOGIN_FAIL_ACTION,
      payload: err,
    });
  }
}

function* logOut() {
  logoutChannel.postMessage("logout");
  yield localStorage.removeItem("Psbtoken");
  yield localStorage.removeItem("PsbUserRole");
  yield localStorage.removeItem("PsbUser");
  yield put(push("/login"));
}

export function* LoginActionWatcher() {
  yield takeEvery(LOGIN_INIT_ACTION, login);
  yield takeEvery(LOGOUT_ACTION, logOut);
}
