import React, { useState, useEffect } from "react";
import { Card, Table, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { IconContext } from "react-icons";
import { BsXCircle } from "react-icons/bs";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ReactTimeAgo from "react-time-ago";
import CardHead from "../Common/cardHead";
import Pagination from "../Common/Components/Pagination";
import NoDataMsg from "../Common/NodataMsg";
import "./style.css";

var dateFormat = require("dateformat");
TimeAgo.addDefaultLocale(en);

const Notifications = (props) => {
  const [page, setPage] = useState(0);
  const history = useHistory();

  useEffect(() => {
    props.fetchNotification({ page: page + 1 });
  }, []);

  const updateNotificatn = (item) => {
    item.read === false && props.updateNotification(item);
  };

  const navigateToPage = (pageDetails) => {
    history.push({
      pathname: `/landingpage/${pageDetails.projectId}`,
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage);

    props.fetchNotification({ page: selectedPage + 1 });
  };

  let p = page + 1;
  let count = props.notificationCount || 0;
  let countPagination = Math.ceil(count / 5);

  return (
    <>
      {" "}
      <div className="masterDataCardDiv">
        <div>
          <Row className="btnrow">
            <Col lg={5} md={12} sm={12} xs={12} className="btnsearchfiltercol">
              <CardHead />
            </Col>
          </Row>
        </div>
        <Card>
          <div className="maintabdiv">
            <Table striped responsive className="cmnMainTable">
              <Row className=" notifiheader">
                <Col className="notificationheading">
                  <h4>Notifications</h4>
                </Col>
                <Col>
                  {" "}
                  <div className="editprofclose resetclose">
                    <Link
                      to="/landingpage"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <IconContext.Provider
                        value={{
                          className: "global-class-name close-position-reset",
                        }}
                      >
                        <BsXCircle />
                      </IconContext.Provider>
                    </Link>
                  </div>
                </Col>
              </Row>
              {props.notifications &&
                props.notifications.map((item) => (
                  <Row
                    className="notifydatas"
                    onClick={() => {
                      updateNotificatn(item);
                      navigateToPage(item);
                    }}
                  >
                    <Col xl={1}>
                      {/* <i className="fa fa-envelope-square notifyreficon"></i> */}
                      {item !== null && item.read ? (
                        <i className="fa fa-envelope-open  fa-sm " />
                      ) : (
                        <i className="fa fa-envelope  fa-sm " />
                      )}
                    </Col>
                    <Col xl={7}>
                      <div>
                        <h5>{item.subject}</h5>
                      </div>
                      <div>{item.message}</div>
                    </Col>
                    <Col xl={3}>
                      <div>
                        <h6>
                          {item !== null && item.createdAt ? (
                            <ReactTimeAgo
                              date={item.createdAt}
                              locale="en-US"
                            />
                          ) : null}
                        </h6>
                      </div>
                      <div>
                        <h6>
                          {" "}
                          {item !== null && item.createdAt
                            ? dateFormat(item.createdAt, "dd/mm/yyyy")
                            : null}
                          <nspn> - </nspn>
                          {item !== null && item.createdAt
                            ? dateFormat(item.createdAt, "h:MM TT")
                            : null}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                ))}
            </Table>
            {!props.notifications ||
              (!props.notifications.length && <NoDataMsg msg="notification" />)}
            <Pagination
              page={page}
              countPagination={countPagination}
              handlePageClick={handlePageClick}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default Notifications;
