import { push } from "connected-react-router";
import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });
    if (!response.ok) {
      throw response;
    } else {
      if (value.type === "file") {
        imgFun(response, value);
        yield put({
          type: `${value.successAction}`,
        });
        // Use the 'Content-Disposition' header to get the file name
      } else {
        const resJSON = yield response.json();

        yield put({
          type: `${value.successAction}`,
          payload: resJSON,
        });
        if (value.extraAction) {
          yield put({
            type: `${value.extraAction}`,
            payload: resJSON,
          });
        }
        return resJSON;
      }
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
  }
}

const imgFun = async (datas, value) => {
  let data = await datas.blob();
  const fileUrl = URL.createObjectURL(data);
  const a = document.createElement("a");
  a.href = fileUrl;
  a.download = value?.projectId
    ? `Report-${value?.projectId}.docx`
    : "report.docx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

function* addProject(value) {
  let user = JSON.parse(localStorage.getItem("PsbUser"));
  const data = {
    userId: user.id,
    name: value.value.name,
    projectType: value.value.projectType,
    projectDevelopmntStage: value.value.projectDevelopmntStage,
    opex: value.value.opex,
    operationPhaseDuration: value.value.operationPhaseDuration,
    interventionType: value.value.interventionType,
    endOfPeriodAnalysis: value.value.endOfPeriodAnalysis,
    dateOfCarbonFootPrint: value.value.dateOfCarbonFootPrint,
    country: value.value.country,
    currency: value.value.currency,
    contactPerson: value.value.contactPerson,
    constructionStartDt: value.value.constructionStartDt,
    capex: value.value.capex,
    desc: value.value.desc,
    refDesc: value.value.refDesc,
    projectSegment: value.value.projectSegment,
    modeOfCalculation: value.value.modeOfCalculation,
  };

  try {
    let params = {
      api: `${appConfig.ip}/project`,
      method: "POST",
      successAction: actionTypes.ADD_PROJECTS_SUCCESS_ACTION,
      failAction: actionTypes.ADD_PROJECTS_FAIL_ACTION,
      body: JSON.stringify(data),
    };

    let res = yield call(commonFunction, params);

    if (res) {
      yield put({
        type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
        value: res,
      });
      yield put(push(`/createproject/${res.id}/calculation`));
    }
    yield toast.success("Project added successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* updateProjectData(value) {
  let id = value.projectData.id;
  const data = {
    userId: value.projectData.userId,
    name: value.projectData.name,
    projectType: value.projectData.projectType,
    projectDevelopmntStage: value.projectData.projectDevelopmntStage,
    opex: value.projectData.opex,
    operationPhaseDuration: value.projectData.operationPhaseDuration,
    interventionType: value.projectData.interventionType,
    endOfPeriodAnalysis: value.projectData.endOfPeriodAnalysis,
    dateOfCarbonFootPrint: value.projectData.dateOfCarbonFootPrint,
    country: value.projectData.country,
    contactPerson: value.projectData.contactPerson,
    constructionStartDt: value.projectData.constructionStartDt,
    capex: value.projectData.capex,
    desc: value.projectData.desc,
    refDesc: value.projectData.refDesc,
    projectSegment: value.projectData.projectSegment,
    industrialSite: value.projectData.industrialSite,
    currency: value.projectData.currency,
    electricalPowerLine: value.projectData.electricalPowerLine
      ? value.projectData.electricalPowerLine
      : [],
    substation: value.projectData.substation
      ? value.projectData.substation
      : [],
    freight: value.projectData.freight ? value.projectData.freight : [],
    clearing: value.projectData.clearing ? value.projectData.clearing : [],

    operationPhaseEnergy: value.projectData.operationPhaseEnergy
      ? value.projectData.operationPhaseEnergy
      : [],
    refOperationPhaseEnergy: value.projectData.refOperationPhaseEnergy
      ? value.projectData.refOperationPhaseEnergy
      : [],

    operationPhaseProcessEmission: value.projectData
      .operationPhaseProcessEmission
      ? value.projectData.operationPhaseProcessEmission
      : [],
    refOperationPhaseProcessEmission: value.projectData
      .refOperationPhaseProcessEmission
      ? value.projectData.refOperationPhaseProcessEmission
      : [],
    status: value.projectData.status,
    refIndustrialSite: value.projectData.refIndustrialSite,
    refElectricalPowerLine: value.projectData.refElectricalPowerLine,
    refSubstation: value.projectData.refSubstation,
    refFreight: value.projectData.refFreight,
    refClearing: value.projectData.refClearing,
    clearingSimplified: value.projectData.clearingSimplified,
    dataCenterSimplified: value.projectData.dataCenterSimplified,
    electricPowerLineSimplified: value.projectData.electricPowerLineSimplified,
    industrialSiteSimplified: value.projectData.industrialSiteSimplified,
    substationSimplified: value.projectData?.substationSimplified,
    freightSimplified: value.projectData?.freightSimplified,
    operationDataCenter: value?.projectData?.operationDataCenter,
    operationIndustrial: value?.projectData?.operationIndustrial,
    refOperationDataCenter: value?.projectData?.refOperationDataCenter,
    refOperationIndustrial: value?.projectData?.refOperationIndustrial,
    revision: value?.projectData?.revision,
    revisionComment: value?.projectData?.revisionComment || "Nil",
  };

  try {
    let params = {
      api: `${appConfig.ip}/project/` + id,
      method: "PUT",
      successAction: actionTypes.UPDATE_PROJECTS_DATA_SUCCESS_ACTION,
      failAction: actionTypes.UPDATE_PROJECTS_DATA_FAIL_ACTION,
      body: JSON.stringify(data),
    };

    let res = yield call(commonFunction, params);

    if (res) {
      yield put({
        type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
        value: res,
      });
    }
  } catch (error) {}
}

function* fetchProjectById(value) {
  let id = value.value;

  try {
    let params = {
      api: `${appConfig.ip}/project/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_PROJECTBYID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_PROJECTBYID_FAIL_ACTION,
    };
    let res = yield call(commonFunction, params);

    if (res) {
      yield put({
        type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
        value: res,
      });
    }
  } catch (error) {}
}

function* fetchRevisionById(value) {
  let id = value.value;

  try {
    let params = {
      api: `${appConfig.ip}/projectHistory/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_REVISIONBYID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_REVISIONBYID_FAIL_ACTION,
    };
    let res = yield call(commonFunction, params);

    if (res) {
      yield put({
        type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
        value: res,
      });
    }
  } catch (error) {}
}

function* downloadReport(value) {
  let id = value.value;

  try {
    let params = {
      api: `${appConfig.ip}/project/exportWord/${id}`,
      method: "GET",
      successAction: actionTypes.DOWNLOAD_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.DOWNLOAD_REPORT_FAIL_ACTION,
      type: "file",
      projectId: value?.projectId,
    };
    let res = yield call(commonFunction, params);
  } catch (error) {}
}

function* downloadRevisionReport(value) {
  let id = value.value;

  try {
    let params = {
      api: `${appConfig.ip}/projectHistory/exportWord/${id}`,
      method: "GET",
      successAction: actionTypes.DOWNLOAD_REVISION_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.DOWNLOAD_REVISION_REPORT_FAIL_ACTION,
      type: "file",
      projectId: value?.projectId,
    };
    let res = yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchEmissionFactorByNo(value) {
  let no = value?.value?.no || "";
  let section_2No = value?.value?.section_2No || "";
  let section_2 = value?.value?.section_2 || "";
  let limit = value?.value?.limit || 30;
  let parameter = value?.value?.parameter || "";
  try {
    let params = {
      api: `${appConfig.ip}/emissionFactor?no=${no}&section_2=${section_2}&limit=${limit}&parameter=${parameter}`,
      method: "GET",
      successAction: actionTypes.FETCH_EF_BY_NO_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_EF_BY_NO_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* addRevision(value) {
  const data = {
    ...value.revisionData,
    projectId: value.revisionData.projectId,
  };

  try {
    let params = {
      api: `${appConfig.ip}/projectHistory`,
      method: "POST",
      successAction: actionTypes.ADD_REVISION_SUCCESS_ACTION,
      failAction: actionTypes.ADD_REVISION_FAIL_ACTION,
      body: JSON.stringify(data),
    };

    let res = yield call(commonFunction, params);

    if (res) {
      yield put({
        type: actionTypes.UPDATE_PROJECTS_DATA_INIT_ACTION,
        projectData: { ...res, id: value.revisionData.id },
      });
      yield toast.success("Revision added successfully", {
        autoClose: 3000,
      });
    }
  } catch (error) {}
}

function* fetchRevisionList(value) {
  try {
    let params = {
      api: `${appConfig.ip}/projectHistory?projectId=${value?.item}`,
      method: "GET",
      successAction: actionTypes.FETCH_REVISIONLIST_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_REVISIONLIST_FAIL_ACTION,
    };
    let res = yield call(commonFunction, params);
  } catch (error) {}
}

export function* CreateProjectWatcher() {
  yield takeEvery(actionTypes.ADD_PROJECTS_INIT_ACTION, addProject);
  yield takeEvery(
    actionTypes.UPDATE_PROJECTS_DATA_INIT_ACTION,
    updateProjectData
  );
  yield takeEvery(actionTypes.FETCH_PROJECTBYID_INIT_ACTION, fetchProjectById);
  yield takeEvery(actionTypes.DOWNLOAD_REPORT_INIT_ACTION, downloadReport);
  yield takeEvery(
    actionTypes.DOWNLOAD_REVISION_REPORT_INIT_ACTION,
    downloadRevisionReport
  );
  yield takeEvery(
    actionTypes.FETCH_EF_BY_NO_INIT_ACTION,
    fetchEmissionFactorByNo
  );

  yield takeEvery(
    actionTypes.FETCH_REVISIONBYID_INIT_ACTION,
    fetchRevisionById
  );
  yield takeEvery(actionTypes.ADD_REVISION_INIT_ACTION, addRevision);
  yield takeEvery(
    actionTypes.FETCH_REVISIONLIST_INIT_ACTION,
    fetchRevisionList
  );
}
