export const FETCH_C4RATIO_INIT_ACTION = "FETCH_C4RATIO_INIT_ACTION";
export const FETCH_C4RATIO_SUCCESS_ACTION =
  FETCH_C4RATIO_INIT_ACTION + "_SUCCESS";
export const FETCH_C4RATIO_FAIL_ACTION = FETCH_C4RATIO_INIT_ACTION + "_ERROR";

export const FETCH_C4RATIOBYID_INIT_ACTION = "FETCH_C4RATIOBYID_INIT_ACTION";
export const FETCH_C4RATIOBYID_SUCCESS_ACTION =
  FETCH_C4RATIOBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_C4RATIOBYID_FAIL_ACTION =
  FETCH_C4RATIOBYID_INIT_ACTION + "_ERROR";

export const ADD_C4RATIO_INIT_ACTION = "ADD_C4RATIO_INIT_ACTION";
export const ADD_C4RATIO_SUCCESS_ACTION = ADD_C4RATIO_INIT_ACTION + "_SUCCESS";
export const ADD_C4RATIO_FAIL_ACTION = ADD_C4RATIO_INIT_ACTION + "_ERROR";
