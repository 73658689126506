export const ADD_COUNTRYCURRENCY_INIT_ACTION =
  "ADD_COUNTRYCURRENCY_INIT_ACTION";
export const ADD_COUNTRYCURRENCY_SUCCESS_ACTION =
  ADD_COUNTRYCURRENCY_INIT_ACTION + "_SUCCESS";
export const ADD_COUNTRYCURRENCY_FAIL_ACTION =
  ADD_COUNTRYCURRENCY_INIT_ACTION + "_ERROR";

export const FETCH_COUNTRYCURRENCY_INIT_ACTION =
  "FETCH_COUNTRYCURRENCY_INIT_ACTION";
export const FETCH_COUNTRYCURRENCY_SUCCESS_ACTION =
  FETCH_COUNTRYCURRENCY_INIT_ACTION + "_SUCCESS";
export const FETCH_COUNTRYCURRENCY_FAIL_ACTION =
  FETCH_COUNTRYCURRENCY_INIT_ACTION + "_ERROR";

export const FETCH_COUNTRYCURRENCYID_INIT_ACTION =
  "FETCH_COUNTRYCURRENCYID_INIT_ACTION";
export const FETCH_COUNTRYCURRENCYID_SUCCESS_ACTION =
  FETCH_COUNTRYCURRENCYID_INIT_ACTION + "_SUCCESS";
export const FETCH_COUNTRYCURRENCYID_FAIL_ACTION =
  FETCH_COUNTRYCURRENCYID_INIT_ACTION + "_ERROR";

export const EDIT_COUNTRYCURRENCY_INIT_ACTION =
  "EDIT_COUNTRYCURRENCY _INIT_ACTION";
export const EDIT_COUNTRYCURRENCY_SUCCESS_ACTION =
  EDIT_COUNTRYCURRENCY_INIT_ACTION + "_SUCCESS";
export const EDIT_COUNTRYCURRENCY_FAIL_ACTION =
  EDIT_COUNTRYCURRENCY_INIT_ACTION + "_ERROR";

export const DELETE_COUNTRYCURRENCY_INIT_ACTION =
  "DELETE_COUNTRYCURRENCY _INIT_ACTION";
export const DELETE_COUNTRYCURRENCY_SUCCESS_ACTION =
  DELETE_COUNTRYCURRENCY_INIT_ACTION + "_SUCCESS";
export const DELETE_COUNTRYCURRENCY_FAIL_ACTION =
  DELETE_COUNTRYCURRENCY_INIT_ACTION + "_ERROR";
