import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
};

export default function WordDocxReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_WORD_DOC_SUCCESS_ACTION:
      return {
        ...state,
        wordDocx: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
