import * as actionTypes from "./constants";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// function* commonFunction(value) {
//   const Psbtoken = localStorage.getItem("Psbtoken");
//   try {
//     const response = yield fetch(`${value.api}`, {
//       method: `${value.method}`,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${Psbtoken}`,
//       },
//       body: value.body ? value.body : null,
//     });
//     if (!response.ok) {
//       throw response;
//     } else {
//       const resJSON = yield response.json();

//       yield put({
//         type: `${value.successAction}`,
//         payload: resJSON,
//       });
//       if (value.extraAction) {
//         yield put({
//           type: `${value.extraAction}`,
//           payload: resJSON,
//         });
//       }
//     }
//   } catch (error) {
//     console.error("error", error);
//     yield put({ type: `${value.failAction}`, error: error });
//   }
// }
function* commonFunction(value, toastSuccess = false, toastError = true) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Bad Request - Field must not be empty";
    } else if (error.status === 401) {
      message = "Undefined";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status === 404) {
      message = "Not Found";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(`Error: ${message}`, { autoClose: 3000 });
    }

    throw error;
  }
}

function* fetchProjectType(value) {
  let searchVal =
    value && value.value && value.value.searchVal ? value.value.searchVal : "";
  try {
    let page = (value.value && value.value.page) || 1;
    let params = {
      page: page,
      api: `${appConfig.ip}/projectType?ser=${searchVal}&isActive=true&limit=10&page=${page}`,
      method: "GET",
      successAction: actionTypes.FETCH_PROJECTTYPE_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_PROJECTTYPE_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* addProjectType(value) {
  const data = {
    name: value.value.name,
    desc: value.value.desc,
  };
  try {
    let params = {
      api: `${appConfig.ip}/projectType`,
      method: "POST",
      successAction: actionTypes.ADD_PROJECTTYPE_SUCCESS_ACTION,
      failAction: actionTypes.ADD_PROJECTTYPE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_PROJECTTYPE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Project type added successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* editProjectType(value) {
  let id = value.id;
  let data = value.value;
  try {
    let params = {
      api: `${appConfig.ip}/projectType/` + id,
      method: "PUT",
      successAction: actionTypes.EDIT_PROJECTTYPE_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_PROJECTTYPE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_PROJECTTYPE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Project type updated successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* deleteProjectType(value) {
  let id = value.data.id;

  let data = {
    name: value.data.name,
    desc: value.data.desc,
    isActive: false,
  };

  try {
    let params = {
      api: `${appConfig.ip}/projectType/` + id,
      method: "PUT",
      successAction: actionTypes.DELETE_PROJECTTYPE_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_PROJECTTYPE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_PROJECTTYPE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Project type deleted successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

export function* ProjectTypeActionWatcher() {
  yield takeEvery(actionTypes.FETCH_PROJECTTYPE_INIT_ACTION, fetchProjectType);
  yield takeEvery(actionTypes.ADD_PROJECTTYPE_INIT_ACTION, addProjectType);
  yield takeEvery(actionTypes.EDIT_PROJECTTYPE_INIT_ACTION, editProjectType);
  yield takeEvery(
    actionTypes.DELETE_PROJECTTYPE_INIT_ACTION,
    deleteProjectType
  );
}
