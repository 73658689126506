import { FETCH_GETEQUATION_SUCCESS_ACTION } from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function DashboardReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_GETEQUATION_SUCCESS_ACTION:
      return {
        ...state,
        projectValue: action.payload.project.valueInTCO2e,
        projectTotalValue: action.payload.project.total,
        simplifiedEq: action.payload.simplifiedEq,
        //Operation phase Industrial
        electricity1:
          action.payload.simplifiedEq.operationIndustrial.electricity1,
        electricity2:
          action.payload.simplifiedEq.operationIndustrial.electricity2,
        otherEnergyConsumptn:
          action.payload.simplifiedEq.operationIndustrial.otherEnergyConsumptn,
        otherEnergyConsumptn2:
          action.payload.simplifiedEq.operationIndustrial.otherEnergyConsumptn2,
        //Clearing
        clearing: action.payload.simplifiedEq.clearing,
        //Electrical Powerline

        aluminumCable:
          action.payload.simplifiedEq.electricalPowerLine.aluminumCable,
        pole: action.payload.simplifiedEq.electricalPowerLine.pole,
        //Data Center
        electricPrdt: action.payload.simplifiedEq.dataCenter.electricPrdt,
        rack: action.payload.simplifiedEq.dataCenter.rack,
      };

    default:
      return {
        ...state,
      };
  }
}
