import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
  emissionFactorByIdList: [],
};

export default function fetchEmissionFactorSessionReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.ADD_EMISSIONFACTORSESSION_INIT_ACTION:
      return {
        ...state,
        emissionFactorSessionList: [],
      };
    case actionTypes.FETCH_EMISSIONFACTORSESSION_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorSessionList: action.payload.rows,

        emissionFactorSessionCount: action.payload.count,
        emissionFactorSessionListxsl: [],
      };
    case actionTypes.FETCH_EMISSIONFACTORSESSIONXSL_SUCCESS_ACTION:
      return {
        ...state,

        emissionFactorSessionListxsl: action.payload.rows,
        // emissionFactorSessionListxslCount: action.payload.count,
      };

    case actionTypes.FETCH_EMISSIONFACTORBYID_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorByIdList: action.payload.rows,
      };

    case actionTypes.EDIT_EMISSIONFACTORSESSION_SUCCESS_ACTION:
      return {
        ...state,
        emissionFactorSessionList: state.emissionFactorSessionList.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        emissionFactorSessionCount: state.emissionFactorSessionCount,
      };
    default:
      return {
        ...state,
      };
  }
}
