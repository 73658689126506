import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS_ACTION:
      return {
        ...state,
        userList: action.payload.rows,
        userCount: action.payload.count,
      };
    case actionTypes.FETCH_USERID_SUCCESS_ACTION:
      return {
        ...state,
        userList: state.userList.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        userCount: state.userCount,
      };
    case actionTypes.CHANGE_STATUS_USER_SUCCESS_ACTION:
      return {
        ...state,
        userList: state.userList.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        userCount: state.userCount,
      };
    default:
      return {
        ...state,
      };
  }
}
