import * as actionTypes from "./constants";
const initialState = {
  processing: false,
  error: false,
  correspondanceZoneList: [],
};

export default function CorrespondanceZoneReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_CORRESPONDANCEZONE_SUCCESS_ACTION:
      return {
        ...state,
        correspondanceZoneList: action.payload.rows,
        correspondanceZoneCount: action.payload.count,
      };
    default:
      return {
        ...state,
      };
  }
}
