import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import Login from "../containers/LoginContainer";
import ForgotPassword from "../containers/ForgotPasswordContainer";
import TermsCondition from "../containers/TermsConditionContainer";
import TermsAndCondition from "../screens/termcondition";

import DefaultLayout from "../containers/DefaultLayout";
import PromtMsg from "../containers/AdminContainer/PromptMsgContainer";
import Notifications from "../screens/Notification";
var history = createBrowserHistory();
// const Login = React.lazy(() => import('../components/Pages/Login'));
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("Psbtoken") ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

function AppNavigator() {
  return (
    <HashRouter basename="/">
      <React.Suspense>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route path="/promptMsg" name="PromtMsg" component={PromtMsg} />,
          <Route
            path="/forgotpassword"
            name="ForgotPassword"
            component={ForgotPassword}
          />
          <Route path="/resetpassword" name="reset" component={DefaultLayout} />
          <Route
            path="/notification"
            name="notification"
            component={DefaultLayout}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
          <Route
            path="/termscondition"
            name="reset"
            component={TermsAndCondition}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}
export default AppNavigator;
