export const FETCH_PROJECTTYPE_INIT_ACTION = "FETCH_PROJECTTYPE_INIT_ACTION";
export const FETCH_PROJECTTYPE_SUCCESS_ACTION =
  FETCH_PROJECTTYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_PROJECTTYPE_FAIL_ACTION =
  FETCH_PROJECTTYPE_INIT_ACTION + "_ERROR";

export const ADD_PROJECTTYPE_INIT_ACTION = "ADD_PROJECTTYPE_INIT_ACTION";
export const ADD_PROJECTTYPE_SUCCESS_ACTION =
  ADD_PROJECTTYPE_INIT_ACTION + "_SUCCESS";
export const ADD_PROJECTTYPE_FAIL_ACTION =
  ADD_PROJECTTYPE_INIT_ACTION + "_ERROR";

export const EDIT_PROJECTTYPE_INIT_ACTION = "EDIT_PROJECTTYPE_INIT_ACTION";
export const EDIT_PROJECTTYPE_SUCCESS_ACTION =
  EDIT_PROJECTTYPE_INIT_ACTION + "_SUCCESS";
export const EDIT_PROJECTTYPE_FAIL_ACTION =
  EDIT_PROJECTTYPE_INIT_ACTION + "_ERROR";

export const DELETE_PROJECTTYPE_INIT_ACTION = "DELETE_PROJECTTYPE_INIT_ACTION";
export const DELETE_PROJECTTYPE_SUCCESS_ACTION =
  DELETE_PROJECTTYPE_INIT_ACTION + "_SUCCESS";
export const DELETE_PROJECTTYPE_FAIL_ACTION =
  DELETE_PROJECTTYPE_INIT_ACTION + "_ERROR";
