import React from "react";
import { put, takeEvery, call } from "redux-saga/effects";
import * as actionTypes from "./constants";
import { toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { appConfig } from "../../config";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });
    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
      return resJSON;
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
  }
}

function* fetchNotification(action) {
  let user = JSON.parse(localStorage.getItem("PsbUser"));
  try {
    let params = {
      api: `${appConfig.ip}/notfctn?limit=5&page=${action.payload.page}&user=${user.id}`,
      method: "GET",
      successAction: actionTypes.FETCH_NOTIFICATION_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_NOTIFICATION_ERROR_ACTION,
    };

    yield call(commonFunction, params);
  } catch (error) {}
}

function* updateNotification(action) {
  let id = action.params.id;

  const data = {
    read: true,
  };

  try {
    let params = {
      api: `${appConfig.ip}/notfctn/${id}`,
      method: "PUT",
      successAction: actionTypes.UPDATE_NOTIFICATIONS_SUCCESS_ACTION,
      failAction: actionTypes.UPDATE_NOTIFICATIONS_ERROR_ACTION,
      body: JSON.stringify(data),
    };

    let res = yield call(commonFunction, params);
    if (res) {
      yield put({
        type: actionTypes.FETCH_NOTIFICATION_INIT_ACTION,
        payload: { page: 1 },
      });
      yield put({
        type: actionTypes.FETCH_UNREADNOTIFICATIONS_INIT_ACTION,
      });
    }
  } catch (error) {}
}

function* unReadNotification(action) {
  let fromPusher = (action.value && action.value.fromPusher) || false;
  let data = (action.value && action.value.data) || null;
  let user = JSON.parse(localStorage.getItem("PsbUser"));
  try {
    let params = {
      api: `${appConfig.ip}/notfctn?read=false&user=${user.id}`,
      method: "GET",
      successAction: actionTypes.FETCH_UNREADNOTIFICATIONS_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_UNREADNOTIFICATIONS_ERROR_ACTION,
    };

    let res = yield call(commonFunction, params);
    if (res) {
      if (fromPusher && data.content) {
        // const Zoom = cssTransition({
        //   enter: "zoomIn",
        //   exit: "zoomOut",

        //   duration: 750,
        // });
        yield toast(
          <p
            style={{
              color: "rgb(5, 51, 76)",
              padding: "9px",
              fontWeight: 600,
              margin: "auto",
            }}
          >
            {data && data.content}
          </p>,
          {
            position: "top-right",
            autoClose: 5000,
            className: "toastClass",
            hideProgressBar: true,
            closeOnClick: true,
          }
        );
      }
    }
  } catch (error) {}
}

export function* NotificationWatcher() {
  yield takeEvery(
    actionTypes.FETCH_NOTIFICATION_INIT_ACTION,
    fetchNotification
  );
  yield takeEvery(
    actionTypes.FETCH_UNREADNOTIFICATIONS_INIT_ACTION,
    unReadNotification
  );
  yield takeEvery(
    actionTypes.UPDATE_NOTIFICATIONS_INIT_ACTION,
    updateNotification
  );
}
