import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
};

export default function MiscellaneousDefaultValueReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_MISCELLANEOUS_SUCCESS_ACTION:
      return {
        ...state,
        miscellaneousDefaultValueList: action.payload.rows,
        miscellaneousDefaultValueCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
