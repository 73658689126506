export const FETCH_GETEQUATION_INIT_ACTION = "FETCH_GETEQUATION_INIT_ACTION";
export const FETCH_GETEQUATION_SUCCESS_ACTION =
  FETCH_GETEQUATION_INIT_ACTION + "_SUCCESS";
export const FETCH_GETEQUATION_FAIL_ACTION =
  FETCH_GETEQUATION_INIT_ACTION + "_ERROR";

export function fetchGetEquation() {
  return {
    type: FETCH_GETEQUATION_INIT_ACTION,
  };
}
