export const FETCH_MISCELLANEOUS_INIT_ACTION = "FETCH_MISCELLANEOUS_INIT_ACTION";
export const FETCH_MISCELLANEOUS_SUCCESS_ACTION = FETCH_MISCELLANEOUS_INIT_ACTION + "_SUCCESS";
export const FETCH_MISCELLANEOUS_FAIL_ACTION = FETCH_MISCELLANEOUS_INIT_ACTION + "_ERROR";

export const ADD_MISCELLANEOUS_INIT_ACTION = "ADD_MISCELLANEOUS_INIT_ACTION";
export const ADD_MISCELLANEOUS_SUCCESS_ACTION = ADD_MISCELLANEOUS_INIT_ACTION + "_SUCCESS";
export const ADD_MISCELLANEOUS_FAIL_ACTION = ADD_MISCELLANEOUS_INIT_ACTION + "_ERROR";

export const EDIT_MISCELLANEOUS_INIT_ACTION = "EDIT_MISCELLANEOUS_INIT_ACTION";
export const EDIT_MISCELLANEOUS_SUCCESS_ACTION = EDIT_MISCELLANEOUS_INIT_ACTION + "_SUCCESS";
export const EDIT_MISCELLANEOUS_FAIL_ACTION = EDIT_MISCELLANEOUS_INIT_ACTION + "_ERROR";

export const DELETE_MISCELLANEOUS_INIT_ACTION = "DELETE_MISCELLANEOUS_INIT_ACTION";
export const DELETE_MISCELLANEOUS_SUCCESS_ACTION = DELETE_MISCELLANEOUS_INIT_ACTION + "_SUCCESS";
export const DELETE_MISCELLANEOUS_FAIL_ACTION = DELETE_MISCELLANEOUS_INIT_ACTION + "_ERROR";
