import * as actionTypes from "./constant";

const sendOtpForgotPassword = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FORGOT_SEND_OTP_PASSWORD_INIT_ACTION,
      value: value,
    });
  };
};
const resendOtpForgotPassword = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FORGOT_RESEND_OTP_PASSWORD_INIT_ACTION,
      value: value,
    });
  };
};

const otpVerifyForgotPassword = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.OTP_VERIFICATION_INIT_ACTION,
      value: value,
    });
  };
};

const reSetNewpassword = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_NEW_PASSWORD_INIT_ACTION,
      value: value,
    });
  };
};

const redirectTologin = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REDIRECT_TO_LOGIN_INIT_ACTION,
      value: value,
    });
  };
};

export default {
  sendOtpForgotPassword,
  resendOtpForgotPassword,
  otpVerifyForgotPassword,
  reSetNewpassword,
  redirectTologin,
};
