export const FETCH_SEADISTANCE_INIT_ACTION = "FETCH_SEADISTANCE_INIT_ACTION";
export const FETCH_SEADISTANCE_SUCCESS_ACTION =
  FETCH_SEADISTANCE_INIT_ACTION + "_SUCCESS";
export const FETCH_SEADISTANCE_FAIL_ACTION =
  FETCH_SEADISTANCE_INIT_ACTION + "_ERROR";

export const ADD_SEADISTANCE_INIT_ACTION = "ADD_SEADISTANCE_INIT_ACTION";
export const ADD_SEADISTANCE_SUCCESS_ACTION =
  ADD_SEADISTANCE_INIT_ACTION + "_SUCCESS";
export const ADD_SEADISTANCE_FAIL_ACTION =
  ADD_SEADISTANCE_INIT_ACTION + "_ERROR";

export const EDIT_SEADISTANCE_INIT_ACTION = "EDIT_SEADISTANCE_INIT_ACTION";
export const EDIT_SEADISTANCE_SUCCESS_ACTION =
  EDIT_SEADISTANCE_INIT_ACTION + "_SUCCESS";
export const EDIT_SEADISTANCE_FAIL_ACTION =
  EDIT_SEADISTANCE_INIT_ACTION + "_ERROR";

export const DELETE_SEADISTANCE_INIT_ACTION = "DELETE_SEADISTANCE_INIT_ACTION";
export const DELETE_SEADISTANCE_SUCCESS_ACTION =
  DELETE_SEADISTANCE_INIT_ACTION + "_SUCCESS";
export const DELETE_SEADISTANCE_FAIL_ACTION =
  DELETE_SEADISTANCE_INIT_ACTION + "_ERROR";
