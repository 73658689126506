export const FETCH_EMISSIONFACTOR_SUBTYPE_INIT_ACTION =
  "FETCH_EMISSIONFACTOR_SUBTYPE_INIT_ACTION";
export const FETCH_EMISSIONFACTOR_SUBTYPE_SUCCESS_ACTION =
  FETCH_EMISSIONFACTOR_SUBTYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_EMISSIONFACTOR_SUBTYPE_FAIL_ACTION =
  FETCH_EMISSIONFACTOR_SUBTYPE_INIT_ACTION + "_ERROR";

export const ADD_EMISSIONFACTOR_SUBTYPE_INIT_ACTION =
  "ADD_EMISSIONFACTOR_SUBTYPE_INIT_ACTION";
export const ADD_EMISSIONFACTOR_SUBTYPE_SUCCESS_ACTION =
  ADD_EMISSIONFACTOR_SUBTYPE_INIT_ACTION + "_SUCCESS";
export const ADD_EMISSIONFACTOR_SUBTYPE_FAIL_ACTION =
  ADD_EMISSIONFACTOR_SUBTYPE_INIT_ACTION + "_ERROR";

export const EDIT_EMISSIONFACTOR_SUBTYPE_INIT_ACTION =
  "EDIT_EMISSIONFACTOR_SUBTYPE_INIT_ACTION";
export const EDIT_EMISSIONFACTOR_SUBTYPE_SUCCESS_ACTION =
  EDIT_EMISSIONFACTOR_SUBTYPE_INIT_ACTION + "_SUCCESS";
export const EDIT_EMISSIONFACTOR_SUBTYPE_FAIL_ACTION =
  EDIT_EMISSIONFACTOR_SUBTYPE_INIT_ACTION + "_ERROR";

export const DELETE_EMISSIONFACTOR_SUBTYPE_INIT_ACTION =
  "DELETE_EMISSIONFACTOR_SUBTYPE_INIT_ACTION";
export const DELETE_EMISSIONFACTOR_SUBTYPE_SUCCESS_ACTION =
  DELETE_EMISSIONFACTOR_SUBTYPE_INIT_ACTION + "_SUCCESS";
export const DELETE_EMISSIONFACTOR_SUBTYPE_FAIL_ACTION =
  DELETE_EMISSIONFACTOR_SUBTYPE_INIT_ACTION + "_ERROR";

export const FETCH_EMISSIONFACTOR_SUBTYPEXSL_INIT_ACTION =
  "FETCH_EMISSIONFACTOR_SUBTYPEXSL_INIT_ACTION";
export const FETCH_EMISSIONFACTOR_SUBTYPEXSL_SUCCESS_ACTION =
  FETCH_EMISSIONFACTOR_SUBTYPEXSL_INIT_ACTION + "_SUCCESS";
export const FETCH_EMISSIONFACTOR_SUBTYPEXSL_FAIL_ACTION =
  FETCH_EMISSIONFACTOR_SUBTYPEXSL_INIT_ACTION + "_ERROR";
