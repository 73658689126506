import * as actionTypes from "./constants";

const fetchNotification = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_NOTIFICATION_INIT_ACTION,
      payload: value,
    });
  };
};
export function unReadNotification(value) {
  return {
    type: actionTypes.FETCH_UNREADNOTIFICATIONS_INIT_ACTION,
    value: value,
  };
}

const updateNotification = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_NOTIFICATIONS_INIT_ACTION,
      params: params,
    });
  };
};

export default {
  fetchNotification,
  updateNotification,
};
