import React from "react";
import { connect } from "react-redux";
import ForgotPassword from "../../screens/ForgotPassword";
import Action from "./action";
const ForgotpasswordContainer = (props) => {
  return (
    <ForgotPassword
      sendOtpForgotPassword={props.sendOtpForgotPassword}
      otpSent={props.otpSent}
      resendOtpForgotPassword={props.resendOtpForgotPassword}
      otpVerifyForgotPassword={props.otpVerifyForgotPassword}
      otpVerified={props.otpVerified}
      reSetNewpassword={props.reSetNewpassword}
      location={props.location}
      selectedTab={props.selectedTab}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.forgotPasswordReducer,
  };
};

export default connect(mapStateToProps, { ...Action })(ForgotpasswordContainer);
