import { put, takeEvery, call } from "redux-saga/effects";
//import { createBrowserHistory } from "history";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";

import {
  FETCH_GETEQUATION_INIT_ACTION,
  FETCH_GETEQUATION_SUCCESS_ACTION,
  FETCH_GETEQUATION_FAIL_ACTION,
} from "./action";

function* fetchGetEquation() {
  const token = localStorage.getItem("Psbtoken");

  try {
    const res = yield fetch(`${appConfig.ip}/landUseEF/getEquation`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.text();

      const module = { exports: {} };
      // eslint-disable-next-line no-new-func
      new Function("module", resJSON)(module);
      let data = module.exports;

      yield put({
        type: FETCH_GETEQUATION_SUCCESS_ACTION,
        payload: data,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_GETEQUATION_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* dashboardActionWatcher() {
  yield takeEvery(FETCH_GETEQUATION_INIT_ACTION, fetchGetEquation);
}
