import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { appConfig } from "../../../config";
import * as actionTypes from "./constant";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });
    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();
      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.successAction) {
        if (value.toastMessage) {
          yield toast.success(value.toastMessage);
        }
      }
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
    if (value.failAction) {
      if (value.toastErrorMessage) {
        yield toast.error(value.toastErrorMessage);
      }
    }
  }
}

function* addEmissionFactorSession2(item) {
  let data = {
    name: item.item.name,
    subType: item.item.subType,
    type: item.item.type,
    typeNo: item.item.typeNo,
    subTypeNo: item.item.subTypeNo,
  };

  try {
    let params = {
      api: `${appConfig.ip}/emissionFactorSection2`,
      method: "POST",
      successAction: actionTypes.ADD_EFSESSION2_SUCCESS_ACTION,
      failAction: actionTypes.ADD_EFSESSION2_FAIL_ACTION,
      extraAction: actionTypes.FETCH_EFSESSION2_INIT_ACTION,
      body: JSON.stringify(data),
      toastErrorMessage: "Sorry, Something went wrong",
      toastMessage: "Emission Factor Section added successfully",
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

function* fetchEmissionFactorSession2(value) {
  let searchVal =
    value && value.value && value.value.searchVal ? value.value.searchVal : "";
  let limit =
    value && value.value && value.value.limit ? value.value.limit : 10;
  let subtype = value.value?.subType
    ? value.value?.subType
    : value.value?.filter2
    ? value.value?.filter2
    : "";

  let filter =
    value && value.value && value.value.filter ? value.value.filter : "";
  try {
    let page = (value.value && value.value.page) || 1;
    let params = {
      page: page,
      api: `${appConfig.ip}/emissionFactorSection2?ser=${searchVal}&isActive=true&limit=${limit}&page=${page}&subType=${subtype}&type=${filter}`,
      method: "GET",
      successAction: actionTypes.FETCH_EFSESSION2_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_EFSESSION2_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

function* fetchEmissionFactorSession2xsl(value) {
  let limit = (value.value.limit && value.value.limit) || 10;
  try {
    let params = {
      api: `${appConfig.ip}/emissionFactorSection2?isActive=true&limit=${limit}`,
      method: "GET",
      successAction: actionTypes.FETCH_EFSESSION2XSL_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_EFSESSION2XSL_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

function* editEmissionFactorSession2(item) {
  let data = {
    name: item.item.name,
    subType: item.item.subType,
    type: item.item.type,
    typeNo: item.value.typeNo,
    subTypeNo: item.value.subTypeNo,
  };

  let id = item.value.id;

  try {
    let params = {
      api: `${appConfig.ip}/emissionFactorSection2/${id}`,
      method: "PUT",
      successAction: actionTypes.EDIT_EFSESSION2_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_EFSESSION2_FAIL_ACTION,
      // extraAction: actionTypes.FETCH_EFSESSION2_INIT_ACTION,
      body: JSON.stringify(data),
      toastErrorMessage: "Sorry, Something went wrong",
      toastMessage: "Emission Factor Section updated successfully",
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

function* deleteEFSession2(item) {
  let id = item.item.id;
  let data = {
    name: item.item.name,
    subType: item.item.subType,
    type: item.item.type,
    typeNo: item.item.typeNo,
    subTypeNo: item.item.subTypeNo,
    isActive: false,
  };

  try {
    let params = {
      api: `${appConfig.ip}/emissionFactorSection2/${id}`,
      method: "PUT",
      successAction: actionTypes.DELETE_EFSESSION2_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_EFSESSION2_FAIL_ACTION,
      extraAction: actionTypes.FETCH_EFSESSION2_INIT_ACTION,
      body: JSON.stringify(data),
      toastErrorMessage: "Sorry, Something went wrong",
      toastMessage: "Emission Factor Section deleted successfully",
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

function* fetchSubTypeEmissionFactor(value) {
  let id = value?.data?.id?.data;

  let url = "";
  if (value && value.data && value.data.id.data) {
    url = `&type=${id}`;
  }

  try {
    let params = {
      api: `${appConfig.ip}/emissnFactorSubType?isActive=true${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_SUBTYPEEMISSIONFACTOR_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_SUBTYPEEMISSIONFACTOR_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

export function* EmissionFactorSession2ActionWatcher() {
  yield takeEvery(
    actionTypes.ADD_EFSESSION2_INIT_ACTION,
    addEmissionFactorSession2
  );
  yield takeEvery(
    actionTypes.FETCH_EFSESSION2_INIT_ACTION,
    fetchEmissionFactorSession2
  );
  yield takeEvery(
    actionTypes.EDIT_EFSESSION2_INIT_ACTION,
    editEmissionFactorSession2
  );
  yield takeEvery(actionTypes.DELETE_EFSESSION2_INIT_ACTION, deleteEFSession2);

  yield takeEvery(
    actionTypes.FETCH_SUBTYPEEMISSIONFACTOR_INIT_ACTION,
    fetchSubTypeEmissionFactor
  );
  yield takeEvery(
    actionTypes.FETCH_EFSESSION2XSL_INIT_ACTION,
    fetchEmissionFactorSession2xsl
  );
}
