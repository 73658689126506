import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });
    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
  }
}

function* addHarmonisedBaseline(value) {
  const data = {
    name: value.values.name,
    unit: value.values.unit,
    type: value.values.type,
    cm7525: value.values.cm7525,
    bm: (value.values.bm && value.values.bm) || 0,
    om: (value.values.om && value.values.om) || 0,
    cm5050: (value.values.cm5050 && value.values.cm5050) || 0,
    cmLF100: (value.values.cmLF100 && value.values.cmLF100) || 0,
  };

  try {
    let params = {
      api: `${appConfig.ip}/harmonisedBaseline`,
      method: "POST",
      successAction: actionTypes.ADD_HARMONISEDBASELINE_SUCCESS_ACTION,
      failAction: actionTypes.ADD_HARMONISEDBASELINE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_HARMONISEDBASELINE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Harmonised Baseline added successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

function* fetchHarmonisedBaseline(value) {
  let page = (value.value && value.value.page) || 1;
  let searchVal = (value.value && value.value.searchVal) || "";

  try {
    let params = {
      page: page,
      api: `${appConfig.ip}/harmonisedBaseline?isActive=true&limit=10&page=${page}&ser=${searchVal}`,
      method: "GET",
      successAction: actionTypes.FETCH_HARMONISEDBASELINE_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_HARMONISEDBASELINE_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* editHarmonisedBaseline(value) {
  let id = value.item.id;
  let type = value.value.type;
  let data = {
    type: value.value.type,
    name: value.value.name,
    unit: value.value.unit,
    cm7525: value.value.cm7525,
    //Baselines for mini-grids & off-grid diesel systems
    cm5050:
      type === "Baselines for mini-grids & off-grid diesel systems"
        ? value.value.cm5050
        : 0,
    cmLF100:
      type === "Baselines for mini-grids & off-grid diesel systems"
        ? value.value.cmLF100
        : 0,
    //Interim Harmonised Baselines
    bm: type === "Interim Harmonised Baselines" ? value.value.bm : 0,
    om: type === "Interim Harmonised Baselines" ? value.value.om : 0,
  };

  try {
    let params = {
      api: `${appConfig.ip}/harmonisedBaseline/` + id,
      method: "PUT",
      successAction: actionTypes.EDIT_HARMONISEDBASELINE_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_HARMONISEDBASELINE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_HARMONISEDBASELINE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Harmonised Baseline updated successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

function* deleteHarmonisedBaseline(item) {
  let id = item.item.id;
  let data = {
    type: item.item.type,
    name: item.item.name,
    unit: item.item.unit,
    cm7525: item.item.cm7525,
    cm5050: item.item.cm5050,
    cmLF100: item.item.cmLF100,
    bm: item.item.bm,
    om: item.item.om,
    isActive: false,
  };
  try {
    let params = {
      api: `${appConfig.ip}/harmonisedBaseline/` + id,
      method: "PUT",
      successAction: actionTypes.DELETE_HARMONISEDBASELINE_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_HARMONISEDBASELINE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_HARMONISEDBASELINE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Harmonised Baseline deleted successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

export function* HarmonisedBaselineActionWatcher() {
  yield takeEvery(
    actionTypes.ADD_HARMONISEDBASELINE_INIT_ACTION,
    addHarmonisedBaseline
  );
  yield takeEvery(
    actionTypes.FETCH_HARMONISEDBASELINE_INIT_ACTION,
    fetchHarmonisedBaseline
  );
  yield takeEvery(
    actionTypes.EDIT_HARMONISEDBASELINE_INIT_ACTION,
    editHarmonisedBaseline
  );
  yield takeEvery(
    actionTypes.DELETE_HARMONISEDBASELINE_INIT_ACTION,
    deleteHarmonisedBaseline
  );
}
