import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
};

export default function HarmonisedBaselineReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_HARMONISEDBASELINE_SUCCESS_ACTION:
      return {
        ...state,
        harmonisedBaselineType: action.payload.rows,
        harmonisedBaselineCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
