export const ADD_INTERVENTIONTYPE_INIT_ACTION =
  "ADD_INTERVENTIONTYPE_INIT_ACTION";
export const ADD_INTERVENTIONTYPE_SUCCESS_ACTION =
  ADD_INTERVENTIONTYPE_INIT_ACTION + "_SUCCESS";
export const ADD_INTERVENTIONTYPE_FAIL_ACTION =
  ADD_INTERVENTIONTYPE_INIT_ACTION + "_ERROR";

export const FETCH_INTERVENTIONTYPE_INIT_ACTION =
  "FETCH_INTERVENTIONTYPE_INIT_ACTION";
export const FETCH_INTERVENTIONTYPE_SUCCESS_ACTION =
  FETCH_INTERVENTIONTYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_INTERVENTIONTYPE_FAIL_ACTION =
  FETCH_INTERVENTIONTYPE_INIT_ACTION + "_ERROR";

export const EDIT_INTERVENTIONTYPE_INIT_ACTION =
  "EDIT_INTERVENTIONTYPE _INIT_ACTION";
export const EDIT_INTERVENTIONTYPE_SUCCESS_ACTION =
  EDIT_INTERVENTIONTYPE_INIT_ACTION + "_SUCCESS";
export const EDIT_INTERVENTIONTYPE_FAIL_ACTION =
  EDIT_INTERVENTIONTYPE_INIT_ACTION + "_ERROR";

export const DELETE_INTERVENTIONTYPE_INIT_ACTION =
  "DELETE_INTERVENTIONTYPE _INIT_ACTION";
export const DELETE_INTERVENTIONTYPE_SUCCESS_ACTION =
  DELETE_INTERVENTIONTYPE_INIT_ACTION + "_SUCCESS";
export const DELETE_INTERVENTIONTYPE_FAIL_ACTION =
  DELETE_INTERVENTIONTYPE_INIT_ACTION + "_ERROR";
