import { call, all } from "redux-saga/effects";

import { LoginActionWatcher } from "../containers/LoginContainer/saga";
import { dashboardActionWatcher } from "../containers/DefaultLayout/saga";
import { resetPasswordVoucher } from "../containers/ResetPasswordContainer/saga";
import { CreateProjectWatcher } from "../containers/CreateProjectContainer/saga";
import { MyProjectActionWatcher } from "../containers/LandingPageContainer/saga";
import { ForgotResetPwdActionWatcher } from "../containers/ForgotPasswordContainer/saga";
//ADMIN
import { CountryActionWatcher } from "../containers/AdminContainer/CountryContainer/saga";
import { CurrencyActionWatcher } from "../containers/AdminContainer/CurrencyContainer/saga";
import { UserActionWatcher } from "../containers/AdminContainer/UserContainer/saga";
import { CountryCurrencyActionWatcher } from "../containers/AdminContainer/CountryCurrencyContainer/saga";
import { ProjectTypeActionWatcher } from "../containers/AdminContainer/ProjectTypeContainer/saga";
import { DevelopmentStageActionWatcher } from "../containers/AdminContainer/DevelopmentStageContainer/saga";
import { InterventionTypeActionWatcher } from "../containers/AdminContainer/InterventionTypeContainer/saga";
import { UnitActionWatcher } from "../containers/AdminContainer/UnitContainer/saga";
import { AddEmissionFactorTypeActionWatcher } from "../containers/AdminContainer/EmissnFactorTypeContainer/saga";
import { MiscellaneousDefaultValueActionWatcher } from "../containers/AdminContainer/MiscellaneousContainer/saga";
import { EmissionFactorSubTypeActionWatcher } from "../containers/AdminContainer/EmissionFactorSubtypeContainer/saga";
import { EmissionFactorSession2ActionWatcher } from "../containers/AdminContainer/EmissionFactorSession2Container/saga";
import { EmissionFactorSessionActionWatcher } from "../containers/AdminContainer/EmissionFactorSessionContainer/saga";
import { NotificationWatcher } from "../containers/NotificationContainer/saga";
import { MsWordActionWatcher } from "../containers/AdminContainer/MsWordContainer/saga";
import { SmkTruckDistanceActionWatcher } from "../containers/AdminContainer/SmkTruckDistContainer/saga";
import { SeaDistanceActionWatcher } from "../containers/AdminContainer/SeaDistanceContainer/saga";
import { LandDistanceActionWatcher } from "../containers/AdminContainer/LandDistanceContainer/saga";
import { CorrespondentsZoneActionWatcher } from "../containers/AdminContainer/CorrespondanceZoneContainer/saga";
import { auditTrailActionWatcher } from "../containers/AdminContainer/AuditTrailContainer/saga";
import { HarmonisedBaselineActionWatcher } from "../containers/AdminContainer/HarmonisedBaselineContainer/saga";
import { C4RatioActionWatcher } from "../containers/AdminContainer/C4Ratio/saga";
import { CO2SimulatorActionWatcher } from "../containers/AdminContainer/CO2SimulatorContainer/saga";
function* rootSaga() {
  yield all([
    call(LoginActionWatcher),
    call(dashboardActionWatcher),
    call(resetPasswordVoucher),
    call(CreateProjectWatcher),
    //ADMIN
    call(CountryActionWatcher),
    call(CurrencyActionWatcher),
    call(UserActionWatcher),
    call(CountryCurrencyActionWatcher),
    call(ProjectTypeActionWatcher),
    call(DevelopmentStageActionWatcher),
    call(InterventionTypeActionWatcher),
    call(UnitActionWatcher),
    call(AddEmissionFactorTypeActionWatcher),
    call(EmissionFactorSubTypeActionWatcher),
    //  ,
    call(MiscellaneousDefaultValueActionWatcher),
    call(EmissionFactorSession2ActionWatcher),
    call(EmissionFactorSessionActionWatcher),
    call(MyProjectActionWatcher),
    call(ForgotResetPwdActionWatcher),
    call(NotificationWatcher),
    call(MsWordActionWatcher),
    call(SmkTruckDistanceActionWatcher),
    call(SeaDistanceActionWatcher),
    call(LandDistanceActionWatcher),
    call(CorrespondentsZoneActionWatcher),
    call(auditTrailActionWatcher),
    call(HarmonisedBaselineActionWatcher),
    call(C4RatioActionWatcher),
    call(CO2SimulatorActionWatcher),
  ]);
}

export default rootSaga;
