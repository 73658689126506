import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// function* commonFunction(value) {
//   const Psbtoken = localStorage.getItem("Psbtoken");
//   try {
//     const response = yield fetch(`${value.api}`, {
//       method: `${value.method}`,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${Psbtoken}`,
//       },
//       body: value.body ? value.body : null,
//     });
//     if (!response.ok) {
//       throw response;
//     } else {
//       const resJSON = yield response.json();

//       yield put({
//         type: `${value.successAction}`,
//         payload: resJSON,
//       });
//       if (value.extraAction) {
//         yield put({
//           type: `${value.extraAction}`,
//           payload: resJSON,
//         });
//       }
//     }
//   } catch (error) {
//     console.error("error", error);
//     yield put({ type: `${value.failAction}`, error: error });
//   }
// }
function* commonFunction(value, toastSuccess = false, toastError = true) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Bad Request - Field must not be empty";
    } else if (error.status === 401) {
      message = "Undefined";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status === 404) {
      message = "Not Found";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(`Error: ${message}`, { autoClose: 3000 });
    }

    throw error;
  }
}

function* addUnit(value) {
  const data = {
    name: value.value.name,
    uSymbol: value.value.uSymbol,
    unitDesc: value.value.unitDesc,
  };

  try {
    let params = {
      api: `${appConfig.ip}/unit`,
      method: "POST",
      successAction: actionTypes.ADD_UNIT_SUCCESS_ACTION,
      failAction: actionTypes.ADD_UNIT_FAIL_ACTION,
      extraAction: actionTypes.FETCH_UNIT_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Unit added successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* fetchUnit(value) {
  let searchVal =
    value && value.value && value.value.searchVal ? value.value.searchVal : "";
  try {
    let page = (value.value && value.value.page) || 1;
    let limit = (value.value && value.value.limit) || 10;

    let params = {
      page: page,
      api: `${appConfig.ip}/unit?ser=${searchVal}&isActive=true&limit=${limit}&page=${page}`,
      method: "GET",
      successAction: actionTypes.FETCH_UNIT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_UNIT_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* editUnit(value) {
  let id = value.value.id;
  let data = value.item;
  try {
    let params = {
      api: `${appConfig.ip}/unit/` + id,
      method: "PUT",
      successAction: actionTypes.EDIT_UNIT_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_UNIT_FAIL_ACTION,
      // extraAction: actionTypes.FETCH_UNIT_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Unit updated successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* deleteUnit(item) {
  let id = item.item.id;
  let data = {
    name: item.item.name,
    uSymbol: item.item.uSymbol,
    unitDesc: item.item.unitDesc,
    isActive: false,
  };
  try {
    let params = {
      api: `${appConfig.ip}/unit/${id}`,
      method: "PUT",
      successAction: actionTypes.DELETE_UNIT_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_UNIT_FAIL_ACTION,
      extraAction: actionTypes.FETCH_UNIT_INIT_ACTION,

      body: JSON.stringify(data),
    };

    yield call(commonFunction, params, true);
    yield toast.success("Unit deleted successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

export function* UnitActionWatcher() {
  yield takeEvery(actionTypes.ADD_UNIT_INIT_ACTION, addUnit);
  yield takeEvery(actionTypes.FETCH_UNIT_INIT_ACTION, fetchUnit);
  yield takeEvery(actionTypes.EDIT_UNIT_INIT_ACTION, editUnit);
  yield takeEvery(actionTypes.DELETE_UNIT_INIT_ACTION, deleteUnit);
}
