import React from "react";
import { Image } from "react-bootstrap";
import "../Common/cmnStyle.css";

function NodataMsg(props) {
  return (
    <div>
      <div className="noDataImgDiv">
        <Image
          className="noDataImg"
          alt="NoDataImg"
          src={require("../../assets/img/nodata1.png")}
        />
      </div>

      {(() => {
        if (props) {
          if (!props.filter && !props.tableData) {
            return <div className="noDataTextDiv">No Data Found</div>;
          } else if (
            props.filter &&
            !props.tableData.length &&
            !props.filter1
          ) {
            return (
              <div className="noDataTextDiv">
                No {props.msgdata} Against {props.filter}
              </div>
            );
          } else if (props.filter && props.filter1 && !props.tableData.length) {
            return (
              <div className="noDataTextDiv">
                No {props.msgdata} Against {props.filter} and {props.filter1}
              </div>
            );
          } else {
            return <div className="noDataTextDiv">No Data Found</div>;
          }
        }
      })()}

      {/* <div className="noDataTextDiv">No Data Found</div> */}
    </div>
  );
}

export default NodataMsg;
