import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./constants";
import { appConfig } from "../../../config";
import "react-toastify/dist/ReactToastify.css";

function* fetchAuditTrail(params) {
  let page = (params.params && params.params.page) || 1;
  let id = (params.params && params.params?.id) || "";

  try {
    const Psbtoken = localStorage.getItem("Psbtoken");
    const res = yield fetch(
      `${appConfig.ip}/auditTrail?docId=${id}&limit=10&page=${page}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Psbtoken}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_AUDITTRAIL_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_AUDITTRAIL_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* auditTrailActionWatcher() {
  yield takeEvery(actionTypes.FETCH_AUDITTRAIL_INIT_ACTION, fetchAuditTrail);
}
