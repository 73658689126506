import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { appConfig } from "../../../config";
import * as actionTypes from "./constant";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });
    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();
      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.successAction) {
        if (value.toastMessage) {
          yield toast.success(value.toastMessage);
        }
      }
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
    if (value.failAction) {
      if (value.toastErrorMessage) {
        yield toast.error(value.toastErrorMessage);
      }
    }
  }
}

function* addEmissionFactorSession(item) {
  let data = {
    parameter: item.item.parameter,
    perUnit: item.item.perUnit,
    section: item.item.section,
    section_1: item.item.section_1,
    section_2: item.item.section_2,
    valueInKgCO2e: item.item.valueInKgCO2e,
    lifetime: item.item.lifetime,
    source: item.item.source,
    validity: item.item.validity,
    comments: item.item.comments,
    sectionNo: item.item.sectionNo,
    section_1No: item.item.section_1No,
    section_2No: item.item.section_2No,
    projectSegment: item.item.projectSegment.map((data) => data.value),
  };

  try {
    let params = {
      api: `${appConfig.ip}/emissionFactor`,
      method: "POST",
      successAction: actionTypes.ADD_EMISSIONFACTORSESSION_SUCCESS_ACTION,
      failAction: actionTypes.ADD_EMISSIONFACTORSESSION_FAIL_ACTION,
      extraAction: actionTypes.FETCH_EMISSIONFACTORSESSION_INIT_ACTION,
      body: JSON.stringify(data),
      toastErrorMessage: "Sorry, Something went wrong",
      toastMessage: "Emission Factor Section added successfully",
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

function* editEmissionFactorSession(item) {
  let data = yield {
    ...item.value,
    ...item.item,
    projectSegment: item.item.projectSegment.map((data) => data.value),
  };
  let id = item.value.id;

  try {
    let params = {
      api: `${appConfig.ip}/emissionFactor/${id}`,
      method: "PUT",
      successAction: actionTypes.EDIT_EMISSIONFACTORSESSION_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_EMISSIONFACTORSESSION_FAIL_ACTION,
      // extraAction: actionTypes.FETCH_EMISSIONFACTORSESSION_INIT_ACTION,
      body: JSON.stringify(data),
      toastErrorMessage: "Sorry, Something went wrong",
      toastMessage: "Emission Factor Section edited successfully",
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

function* fetchEmissionFactorSession(value) {
  let page = (value.value && value.value.page) || 1;
  let searchVal = (value?.value?.searchVal && value.value.searchVal) || "";
  let section = (value?.value?.filter1 && value.value.filter1) || "";
  let section_1 = (value?.value?.filter2 && value.value.filter2) || "";
  let section_2 = (value?.value?.subSection && value.value.subSection) || "";
  let limit = (value?.value?.limit && value.value.limit) || 10;
  try {
    let params = {
      page: page,
      api: `${appConfig.ip}/emissionFactor?isActive=true&limit=${limit}&page=${page}&ser=${searchVal}&section=${section}&section_1=${section_1}&section_2=${section_2}`,
      method: "GET",
      successAction: actionTypes.FETCH_EMISSIONFACTORSESSION_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_EMISSIONFACTORSESSION_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* deleteEmissionFactorSession(item) {
  let id = item.item.id;
  let data = {
    parameter: item.item.parameter,
    perUnit: item.item.perUnit,
    section: item.item.section,
    section_1: item.item.section_1,
    section_2: item.item.section_2,
    valueInKgCO2e: item.item.valueInKgCO2e,
    isActive: false,
    lifetime: item.item.lifetime,
    source: item.item.source,
    validity: item.item.validity,
    comments: item.item.comments,
    sectionNo: item.item.sectionNo,
    section_1No: item.item.section_1No,
    section_2No: item.item.section_2No,
    deleted: true,
  };

  try {
    let params = {
      api: `${appConfig.ip}/emissionFactor/${id}`,
      method: "PUT",
      successAction: actionTypes.DELETE_EMISSIONFACTORSESSION_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_EMISSIONFACTORSESSION_FAIL_ACTION,
      extraAction: actionTypes.FETCH_EMISSIONFACTORSESSION_INIT_ACTION,
      body: JSON.stringify(data),
      toastErrorMessage: "Sorry, Something went wrong",
      toastMessage: "Emission Factor Section deleted successfully",
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

function* fetchEmissionFactorById(value) {
  let type = value?.item?.type || "";
  let limit = (value.item && value.item.limit) || 30;
  let section_1No = value?.item?.section_1No || "";
  let section_2No = value?.item?.section_2No || "";
  let no = value?.item?.no || "";
  let section_2 = value?.item?.section_2 || "";
  try {
    let params = {
      api: `${appConfig.ip}/emissionFactor?section=${type}&limit=${limit}&isActive=true&section_1No=${section_1No}&section_2No=${section_2No}&no=${no}&section_2=${section_2}`,
      method: "GET",
      successAction: actionTypes.FETCH_EMISSIONFACTORBYID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_EMISSIONFACTORBYID_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchEmissionFactorSessionXsl(value) {
  let limit = (value.value.limit && value.value.limit) || 10;

  try {
    let params = {
      api: `${appConfig.ip}/emissionFactor?isActive=true&limit=${limit}`,
      method: "GET",
      successAction: actionTypes.FETCH_EMISSIONFACTORSESSIONXSL_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_EMISSIONFACTORSESSIONXSL_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

export function* EmissionFactorSessionActionWatcher() {
  yield takeEvery(
    actionTypes.ADD_EMISSIONFACTORSESSION_INIT_ACTION,
    addEmissionFactorSession
  );
  yield takeEvery(
    actionTypes.FETCH_EMISSIONFACTORSESSION_INIT_ACTION,
    fetchEmissionFactorSession
  );
  yield takeEvery(
    actionTypes.EDIT_EMISSIONFACTORSESSION_INIT_ACTION,
    editEmissionFactorSession
  );
  yield takeEvery(
    actionTypes.DELETE_EMISSIONFACTORSESSION_INIT_ACTION,
    deleteEmissionFactorSession
  );
  yield takeEvery(
    actionTypes.FETCH_EMISSIONFACTORBYID_INIT_ACTION,
    fetchEmissionFactorById
  );
  yield takeEvery(
    actionTypes.FETCH_EMISSIONFACTORSESSIONXSL_INIT_ACTION,
    fetchEmissionFactorSessionXsl
  );
}
