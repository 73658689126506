import * as actionTypes from "./constants";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });
    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
  }
}

function* fetchCorrespondanceZone(value) {
  try {
    let page = (value.value && value.value.page) || 1;
    let region = value.value && value.value.region;
    let params = {
      page: page,
      api: `${appConfig.ip}/correspondanceZone?isActive=true&limit=10&page=${page}&region=${region}`,
      method: "GET",
      successAction: actionTypes.FETCH_CORRESPONDANCEZONE_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_CORRESPONDANCEZONE_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* addCorrespondanceZone(value) {
  const data = {
    region: value.value.region,
    zone: value.value.zone,
    vesselType: value.value.vesselType,
  };
  try {
    let params = {
      api: `${appConfig.ip}/correspondanceZone`,
      method: "POST",
      successAction: actionTypes.ADD_CORRESPONDANCEZONE_SUCCESS_ACTION,
      failAction: actionTypes.ADD_CORRESPONDANCEZONE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_CORRESPONDANCEZONE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Correspondance zone added successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

function* editCorrespondanceZone(value) {
  let id = value.id;
  let data = value.value;
  try {
    let params = {
      api: `${appConfig.ip}/correspondanceZone/` + id,
      method: "PUT",
      successAction: actionTypes.EDIT_CORRESPONDANCEZONE_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_CORRESPONDANCEZONE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_CORRESPONDANCEZONE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Correspondance zone updated successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

function* deleteCorrespondanceZone(value) {
  let id = value.data.id;

  let data = {
    region: value.data.region,
    zone: value.data.zone,
    vesselType: value.data.vesselType,
    isActive: false,
  };

  try {
    let params = {
      api: `${appConfig.ip}/correspondanceZone/` + id,
      method: "PUT",
      successAction: actionTypes.DELETE_CORRESPONDANCEZONE_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_CORRESPONDANCEZONE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_CORRESPONDANCEZONE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Correspondance zone deleted successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

export function* CorrespondentsZoneActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_CORRESPONDANCEZONE_INIT_ACTION,
    fetchCorrespondanceZone
  );
  yield takeEvery(
    actionTypes.ADD_CORRESPONDANCEZONE_INIT_ACTION,
    addCorrespondanceZone
  );
  yield takeEvery(
    actionTypes.EDIT_CORRESPONDANCEZONE_INIT_ACTION,
    editCorrespondanceZone
  );
  yield takeEvery(
    actionTypes.DELETE_CORRESPONDANCEZONE_INIT_ACTION,
    deleteCorrespondanceZone
  );
}
