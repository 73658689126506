export const FETCH_DEVELOPMENT_STAGE_INIT_ACTION =
  "FETCH_DEVELOPMENT_STAGE_INIT_ACTION";
export const FETCH_DEVELOPMENT_STAGE_SUCCESS_ACTION =
  FETCH_DEVELOPMENT_STAGE_INIT_ACTION + "_SUCCESS";
export const FETCH_DEVELOPMENT_STAGE_FAIL_ACTION =
  FETCH_DEVELOPMENT_STAGE_INIT_ACTION + "_ERROR";

export const ADD_DEVELOPMENT_STAGE_INIT_ACTION =
  "ADD_DEVELOPMENT_STAGE_INIT_ACTION";
export const ADD_DEVELOPMENT_STAGE_SUCCESS_ACTION =
  ADD_DEVELOPMENT_STAGE_INIT_ACTION + "_SUCCESS";
export const ADD_DEVELOPMENT_STAGE_FAIL_ACTION =
  ADD_DEVELOPMENT_STAGE_INIT_ACTION + "_ERROR";

export const EDIT_DEVELOPMENT_STAGE_INIT_ACTION =
  "EDIT_DEVELOPMENT_STAGE_INIT_ACTION";
export const EDIT_DEVELOPMENT_STAGE_SUCCESS_ACTION =
  EDIT_DEVELOPMENT_STAGE_INIT_ACTION + "_SUCCESS";
export const EDIT_DEVELOPMENT_STAGE_FAIL_ACTION =
  EDIT_DEVELOPMENT_STAGE_INIT_ACTION + "_ERROR";

export const DELETE_DEVELOPMENT_STAGE_INIT_ACTION =
  "DELETE_DEVELOPMENT_STAGE_INIT_ACTION";
export const DELETE_DEVELOPMENT_STAGE_SUCCESS_ACTION =
  DELETE_DEVELOPMENT_STAGE_INIT_ACTION + "_SUCCESS";
export const DELETE_DEVELOPMENT_STAGE_FAIL_ACTION =
  DELETE_DEVELOPMENT_STAGE_INIT_ACTION + "_ERROR";
