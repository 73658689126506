import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });
    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
  }
}

function* fetchC4Ratio(value) {
  try {
    let page = (value.value && value.value.page) || 1;
    let searchVal = (value?.value?.searchVal && value?.value?.searchVal) || "";
    let params = {
      page: page,
      api: `${appConfig.ip}/c4ratio?limit=10&page=${page}&q=${searchVal}`,
      method: "GET",
      successAction: actionTypes.FETCH_C4RATIO_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_C4RATIO_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}
function* fetchC4RatioById(value) {
  let id = value.value.no;

  try {
    let params = {
      api: `${appConfig.ip}/c4ratio/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_C4RATIOBYID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_C4RATIOBYID_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}
function* addC4Ratio(value) {
  try {
    let params = {
      api: `${appConfig.ip}`,
      method: "POST",
      successAction: actionTypes.ADD_C4RATIO_SUCCESS_ACTION,
      failAction: actionTypes.ADD_C4RATIO_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

export function* C4RatioActionWatcher() {
  yield takeEvery(actionTypes.FETCH_C4RATIO_INIT_ACTION, fetchC4Ratio);
  yield takeEvery(actionTypes.FETCH_C4RATIOBYID_INIT_ACTION, fetchC4RatioById);
  yield takeEvery(actionTypes.ADD_C4RATIO_INIT_ACTION, addC4Ratio);
}
