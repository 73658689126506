export const FETCH_NOTIFICATION_INIT_ACTION = "FETCH_NOTIFICATION_INIT_ACTION";
export const FETCH_NOTIFICATION_SUCCESS_ACTION =
  FETCH_NOTIFICATION_INIT_ACTION + "_SUCCESS";
export const FETCH_NOTIFICATION_ERROR_ACTION =
  FETCH_NOTIFICATION_INIT_ACTION + "_ERROR";

export const FETCH_UNREADNOTIFICATIONS_INIT_ACTION =
  "FETCH_UNREADNOTIFICATIONS_INIT_ACTION";
export const FETCH_UNREADNOTIFICATIONS_SUCCESS_ACTION =
  FETCH_UNREADNOTIFICATIONS_INIT_ACTION + "_SUCCESS";
export const FETCH_UNREADNOTIFICATIONS_ERROR_ACTION =
  FETCH_UNREADNOTIFICATIONS_INIT_ACTION + "_ERROR";

export const UPDATE_NOTIFICATIONS_INIT_ACTION =
  "UPDATE_NOTIFICATIONS_INIT_ACTION";
export const UPDATE_NOTIFICATIONS_SUCCESS_ACTION =
  UPDATE_NOTIFICATIONS_INIT_ACTION + "_SUCCESS";
export const UPDATE_NOTIFICATIONS_ERROR_ACTION =
  UPDATE_NOTIFICATIONS_INIT_ACTION + "_ERROR";
