export const FETCH_LANDDISTANCE_INIT_ACTION = "FETCH_LANDDISTANCE_INIT_ACTION";
export const FETCH_LANDDISTANCE_SUCCESS_ACTION =
  FETCH_LANDDISTANCE_INIT_ACTION + "_SUCCESS";
export const FETCH_LANDDISTANCE_FAIL_ACTION =
  FETCH_LANDDISTANCE_INIT_ACTION + "_ERROR";

export const ADD_LANDDISTANCE_INIT_ACTION = "ADD_LANDDISTANCE_INIT_ACTION";
export const ADD_LANDDISTANCE_SUCCESS_ACTION =
  ADD_LANDDISTANCE_INIT_ACTION + "_SUCCESS";
export const ADD_LANDDISTANCE_FAIL_ACTION =
  ADD_LANDDISTANCE_INIT_ACTION + "_ERROR";

export const EDIT_LANDDISTANCE_INIT_ACTION = "EDIT_LANDDISTANCE_INIT_ACTION";
export const EDIT_LANDDISTANCE_SUCCESS_ACTION =
  EDIT_LANDDISTANCE_INIT_ACTION + "_SUCCESS";
export const EDIT_LANDDISTANCE_FAIL_ACTION =
  EDIT_LANDDISTANCE_INIT_ACTION + "_ERROR";

export const DELETE_LANDDISTANCE_INIT_ACTION =
  "DELETE_LANDDISTANCE_INIT_ACTION";
export const DELETE_LANDDISTANCE_SUCCESS_ACTION =
  DELETE_LANDDISTANCE_INIT_ACTION + "_SUCCESS";
export const DELETE_LANDDISTANCE_FAIL_ACTION =
  DELETE_LANDDISTANCE_INIT_ACTION + "_ERROR";
