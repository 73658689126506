import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
};

export default function DevelopmentStageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DEVELOPMENT_STAGE_SUCCESS_ACTION:
      return {
        ...state,
        developmentStageList: action.payload.rows,
        developmentStageCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
