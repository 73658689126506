export const FETCH_EMISSIONFACTORTYPE_INIT_ACTION =
  "FETCH_EMISSIONFACTORTYPE_INIT_ACTION";
export const FETCH_EMISSIONFACTORTYPE_SUCCESS_ACTION =
  FETCH_EMISSIONFACTORTYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_EMISSIONFACTORTYPE_FAIL_ACTION =
  FETCH_EMISSIONFACTORTYPE_INIT_ACTION + "_ERROR";

export const FETCH_EMISSIONFACTORTYPEXSL_INIT_ACTION =
  "FETCH_EMISSIONFACTORTYPEXSL_INIT_ACTION";
export const FETCH_EMISSIONFACTORTYPEXSL_SUCCESS_ACTION =
  FETCH_EMISSIONFACTORTYPEXSL_INIT_ACTION + "_SUCCESS";
export const FETCH_EMISSIONFACTORTYPEXSL_FAIL_ACTION =
  FETCH_EMISSIONFACTORTYPEXSL_INIT_ACTION + "_ERROR";

export const ADD_EMISSIONFACTORTYPE_INIT_ACTION =
  "ADD_EMISSIONFACTORTYPE_INIT_ACTION";
export const ADD_EMISSIONFACTORTYPE_SUCCESS_ACTION =
  ADD_EMISSIONFACTORTYPE_INIT_ACTION + "_SUCCESS";
export const ADD_EMISSIONFACTORTYPE_FAIL_ACTION =
  ADD_EMISSIONFACTORTYPE_INIT_ACTION + "_ERROR";

export const EDIT_EMISSIONFACTORTYPE_INIT_ACTION =
  "EDIT_EMISSIONFACTORTYPE_INIT_ACTION";
export const EDIT_EMISSIONFACTORTYPE_SUCCESS_ACTION =
  EDIT_EMISSIONFACTORTYPE_INIT_ACTION + "_SUCCESS";
export const EDIT_EMISSIONFACTORTYPE_FAIL_ACTION =
  EDIT_EMISSIONFACTORTYPE_INIT_ACTION + "_ERROR";

export const DELETE_EMISSIONFACTORTYPE_INIT_ACTION =
  "DELETE_EMISSIONFACTORTYPE_INIT_ACTION";
export const DELETE_EMISSIONFACTORTYPE_SUCCESS_ACTION =
  DELETE_EMISSIONFACTORTYPE_INIT_ACTION + "_SUCCESS";
export const DELETE_EMISSIONFACTORTYPE_FAIL_ACTION =
  DELETE_EMISSIONFACTORTYPE_INIT_ACTION + "_ERROR";
