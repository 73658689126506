import * as actionTypes from "./constants";
import _ from "lodash";

const initialState = {
  processing: false,
  error: false,
  auditTrailList: [],
  auditTrailCount: 0,
};

export default function auditTrailReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_AUDITTRAIL_SUCCESS_ACTION:
      return {
        ...state,
        auditTrailList: action.payload.rows,
        auditTrailCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
