export const FETCH_EMISSIONFACTORSESSION_INIT_ACTION =
  "FETCH_EMISSIONFACTORSESSION_INIT_ACTION";
export const FETCH_EMISSIONFACTORSESSION_SUCCESS_ACTION =
  FETCH_EMISSIONFACTORSESSION_INIT_ACTION + "_SUCCESS";
export const FETCH_EMISSIONFACTORSESSION_FAIL_ACTION =
  FETCH_EMISSIONFACTORSESSION_INIT_ACTION + "_ERROR";

export const FETCH_EMISSIONFACTORSESSIONXSL_INIT_ACTION =
  "FETCH_EMISSIONFACTORSESSIONXSL_INIT_ACTION";
export const FETCH_EMISSIONFACTORSESSIONXSL_SUCCESS_ACTION =
  FETCH_EMISSIONFACTORSESSIONXSL_INIT_ACTION + "_SUCCESS";
export const FETCH_EMISSIONFACTORSESSIONXSL_FAIL_ACTION =
  FETCH_EMISSIONFACTORSESSIONXSL_INIT_ACTION + "_ERROR";

export const ADD_EMISSIONFACTORSESSION_INIT_ACTION =
  "ADD_EMISSIONFACTORSESSION_INIT_ACTION";
export const ADD_EMISSIONFACTORSESSION_SUCCESS_ACTION =
  ADD_EMISSIONFACTORSESSION_INIT_ACTION + "_SUCCESS";
export const ADD_EMISSIONFACTORSESSION_FAIL_ACTION =
  ADD_EMISSIONFACTORSESSION_INIT_ACTION + "_ERROR";

export const EDIT_EMISSIONFACTORSESSION_INIT_ACTION =
  "EDIT_EMISSIONFACTORSESSION_INIT_ACTION";
export const EDIT_EMISSIONFACTORSESSION_SUCCESS_ACTION =
  EDIT_EMISSIONFACTORSESSION_INIT_ACTION + "_SUCCESS";
export const EDIT_EMISSIONFACTORSESSION_FAIL_ACTION =
  EDIT_EMISSIONFACTORSESSION_INIT_ACTION + "_ERROR";

export const DELETE_EMISSIONFACTORSESSION_INIT_ACTION =
  "DELETE_EMISSIONFACTORSESSION_INIT_ACTION";
export const DELETE_EMISSIONFACTORSESSION_SUCCESS_ACTION =
  DELETE_EMISSIONFACTORSESSION_INIT_ACTION + "_SUCCESS";
export const DELETE_EMISSIONFACTORSESSION_FAIL_ACTION =
  DELETE_EMISSIONFACTORSESSION_INIT_ACTION + "_ERROR";

export const FETCH_EMISSIONFACTORBYID_INIT_ACTION =
  "FETCH_EMISSIONFACTORBYID_INIT_ACTION";
export const FETCH_EMISSIONFACTORBYID_SUCCESS_ACTION =
  FETCH_EMISSIONFACTORBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_EMISSIONFACTORBYID_FAIL_ACTION =
  FETCH_EMISSIONFACTORBYID_INIT_ACTION + "_ERROR";
