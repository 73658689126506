export const ADD_COUNTRY_INIT_ACTION = "ADD_COUNTRY_INIT_ACTION";
export const ADD_COUNTRY_SUCCESS_ACTION = ADD_COUNTRY_INIT_ACTION + "_SUCCESS";
export const ADD_COUNTRY_FAIL_ACTION = ADD_COUNTRY_INIT_ACTION + "_ERROR";

export const FETCH_COUNTRY_INIT_ACTION = "FETCH_COUNTRY_INIT_ACTION";
export const FETCH_COUNTRY_SUCCESS_ACTION =
  FETCH_COUNTRY_INIT_ACTION + "_SUCCESS";
export const FETCH_COUNTRY_FAIL_ACTION = FETCH_COUNTRY_INIT_ACTION + "_ERROR";

export const EDIT_COUNTRY_INIT_ACTION = "EDIT_COUNTRY _INIT_ACTION";
export const EDIT_COUNTRY_SUCCESS_ACTION =
  EDIT_COUNTRY_INIT_ACTION + "_SUCCESS";
export const EDIT_COUNTRY_FAIL_ACTION = EDIT_COUNTRY_INIT_ACTION + "_ERROR";

export const DELETE_COUNTRY_INIT_ACTION = "DELETE_COUNTRY _INIT_ACTION";
export const DELETE_COUNTRY_SUCCESS_ACTION =
  DELETE_COUNTRY_INIT_ACTION + "_SUCCESS";
export const DELETE_COUNTRY_FAIL_ACTION = DELETE_COUNTRY_INIT_ACTION + "_ERROR";

export const UPDATE_COUNTRY_ACTION = "UPDATE_COUNTRY_ACTION";
