import * as actionTypes from "./constant";
const initialState = {
  processing: false,
  error: false,
  co2SimulatorList: [],
  co2SimulatorCount: 0,
};

export default function Co2SimulatorReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CO2SIMULATOR_SUCCESS_ACTION:
      return {
        ...state,
        co2SimulatorList: action.payload.rows,
        co2SimulatorCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
