import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  notifications: [],
  notificationCount: 0,
  unReadNotifcationCount: 0,
};

export default function NotificationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATION_SUCCESS_ACTION:
      return {
        ...state,
        notifications: action.payload.rows,
        notificationCount: action.payload.count,
      };
    case actionTypes.FETCH_UNREADNOTIFICATIONS_SUCCESS_ACTION:
      return {
        ...state,
        unReadNotifcationData: action.payload.rows,
        unReadNotifcationCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
