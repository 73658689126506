import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value, toastSuccess = false, toastError = true) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();
      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
      return resJSON;
      // if (toastSuccess) {
      //   yield toast.success("Success", { autoClose: 3000 });
      // }
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Bad Request - Field must not be empty";
    } else if (error.status === 401) {
      message = "Authorization failed! Try again";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status === 404) {
      message = "Not Found";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(`Error: ${message}`, { autoClose: 3000 });
    }

    throw error;
  }
}

function* addCountryCurrency(value) {
  const data = {
    countryId: value.value.countryId,
    currencyId: value.value.currencyId,
  };
  try {
    let params = {
      api: `${appConfig.ip}/countryCurrency`,
      method: "POST",
      successAction: actionTypes.ADD_COUNTRYCURRENCY_SUCCESS_ACTION,
      failAction: actionTypes.ADD_COUNTRYCURRENCY_FAIL_ACTION,
      extraAction: actionTypes.FETCH_COUNTRYCURRENCY_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Country currency successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* fetchCountryCurrency(value) {
  let page = (value.value && value.value.page) || 1;

  try {
    let params = {
      page: page,
      api: `${appConfig.ip}/countryCurrency?isActive=true&limit=${
        value && value.value && value.value.limit ? value.value.limit : 10
      }&page=${page}`,
      method: "GET",
      successAction: actionTypes.FETCH_COUNTRYCURRENCY_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_COUNTRYCURRENCY_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchCountryCurrencyId(value) {
  let id = value;

  try {
    let params = {
      api: `${appConfig.ip}/countryCurrency/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_COUNTRYCURRENCYID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_COUNTRYCURRENCYID_FAIL_ACTION,
    };
    let res = yield call(commonFunction, params);
    if (res) {
      return res;
    }
  } catch (error) {}
}

function* deleteCountryCurrency(value) {
  const data = {
    countryId: value.value.countryId.id,
    currencyId: value.value.currencyId.id,
    isActive: false,
  };

  const id = value.value.id;

  try {
    let params = {
      api: `${appConfig.ip}/countryCurrency/${id}`,
      method: "PUT",
      successAction: actionTypes.DELETE_COUNTRYCURRENCY_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_COUNTRYCURRENCY_FAIL_ACTION,
      extraAction: actionTypes.FETCH_COUNTRYCURRENCY_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);

    yield toast.success("Country currency deleted successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* editCountryCurrency(value) {
  const data = value.item;
  const id = value.value.id;

  try {
    let params = {
      api: `${appConfig.ip}/countryCurrency/${id}`,
      method: "PUT",
      successAction: actionTypes.EDIT_COUNTRYCURRENCY_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_COUNTRYCURRENCY_FAIL_ACTION,
      // extraAction: actionTypes.FETCH_COUNTRYCURRENCY_INIT_ACTION,
      body: JSON.stringify(data),
    };
    let res = yield call(commonFunction, params, true);
    if (res) {
      let resById = yield call(fetchCountryCurrencyId, id);
      if (resById) {
        yield put({
          type: actionTypes.EDIT_COUNTRYCURRENCY_SUCCESS_ACTION,
          payload: resById,
        });
      }
    }
    yield toast.success("Country currency updated successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

export function* CountryCurrencyActionWatcher() {
  yield takeEvery(
    actionTypes.ADD_COUNTRYCURRENCY_INIT_ACTION,
    addCountryCurrency
  );

  yield takeEvery(
    actionTypes.FETCH_COUNTRYCURRENCY_INIT_ACTION,
    fetchCountryCurrency
  );

  yield takeEvery(
    actionTypes.DELETE_COUNTRYCURRENCY_INIT_ACTION,
    deleteCountryCurrency
  );
  yield takeEvery(
    actionTypes.EDIT_COUNTRYCURRENCY_INIT_ACTION,
    editCountryCurrency
  );
  // yield takeEvery(
  //   actionTypes.FETCH_COUNTRYCURRENCYID_INIT_ACTION,
  //   fetchCountryCurrencyId
  // );
}
