export const FETCH_EFSESSION2_INIT_ACTION = "FETCH_EFSESSION2_INIT_ACTION";
export const FETCH_EFSESSION2_SUCCESS_ACTION =
  FETCH_EFSESSION2_INIT_ACTION + "_SUCCESS";
export const FETCH_EFSESSION2_FAIL_ACTION =
  FETCH_EFSESSION2_INIT_ACTION + "_ERROR";

export const ADD_EFSESSION2_INIT_ACTION = "ADD_EFSESSION2_INIT_ACTION";
export const ADD_EFSESSION2_SUCCESS_ACTION =
  ADD_EFSESSION2_INIT_ACTION + "_SUCCESS";
export const ADD_EFSESSION2_FAIL_ACTION = ADD_EFSESSION2_INIT_ACTION + "_ERROR";

export const EDIT_EFSESSION2_INIT_ACTION = "EDIT_EFSESSION2_INIT_ACTION";
export const EDIT_EFSESSION2_SUCCESS_ACTION =
  EDIT_EFSESSION2_INIT_ACTION + "_SUCCESS";
export const EDIT_EFSESSION2_FAIL_ACTION =
  EDIT_EFSESSION2_INIT_ACTION + "_ERROR";

export const DELETE_EFSESSION2_INIT_ACTION = "DELETE_EFSESSION2_INIT_ACTION";
export const DELETE_EFSESSION2_SUCCESS_ACTION =
  DELETE_EFSESSION2_INIT_ACTION + "_SUCCESS";
export const DELETE_EFSESSION2_FAIL_ACTION =
  DELETE_EFSESSION2_INIT_ACTION + "_ERROR";

export const FETCH_SUBTYPEEMISSIONFACTOR_INIT_ACTION =
  "FETCH_SUBTYPEEMISSIONFACTOR_INIT_ACTION";
export const FETCH_SUBTYPEEMISSIONFACTOR_SUCCESS_ACTION =
  FETCH_SUBTYPEEMISSIONFACTOR_INIT_ACTION + "_SUCCESS";
export const FETCH_SUBTYPEEMISSIONFACTOR_FAIL_ACTION =
  FETCH_SUBTYPEEMISSIONFACTOR_INIT_ACTION + "_ERROR";

export const UPDATE_EFSESSION2_ACTION = "UPDATE_EFSESSION2_ACTION";

export const FETCH_EFSESSION2XSL_INIT_ACTION =
  "FETCH_EFSESSION2XSL_INIT_ACTION";
export const FETCH_EFSESSION2XSL_SUCCESS_ACTION =
  FETCH_EFSESSION2XSL_INIT_ACTION + "_SUCCESS";
export const FETCH_EFSESSION2XSL_FAIL_ACTION =
  FETCH_EFSESSION2XSL_INIT_ACTION + "_ERROR";

export const UPDATE_EFSUBTYPE_ACTION = "UPDATE_EFSUBTYPE_ACTION";
