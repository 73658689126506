import * as actionTypes from "./constants";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });
    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({ type: `${value.failAction}`, error: error });
  }
}

function* fetchLandDistance(value) {
  try {
    let page = (value.value && value.value.page) || 1;
    let destination = (value.value && value.value.destination) || "";
    let searchVal = (value.value && value.value.searchVal) || "";
    let params = {
      page: page,
      api: `${appConfig.ip}/mmtLandDist?isActive=true&limit=10&page=${page}&destination=${destination}&ser=${searchVal}`,
      method: "GET",
      successAction: actionTypes.FETCH_LANDDISTANCE_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_LANDDISTANCE_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* addLandDistance(value) {
  const data = {
    meansOfTransport: value.value.meansOfTransport,
    origin: value.value.origin,
    destination: value.value.destination,
    distance: value.value.distance,
    distanceFerry: value.value.distanceFerry,
  };
  try {
    let params = {
      api: `${appConfig.ip}/mmtLandDist`,
      method: "POST",
      successAction: actionTypes.ADD_LANDDISTANCE_SUCCESS_ACTION,
      failAction: actionTypes.ADD_LANDDISTANCE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_LANDDISTANCE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Land distance added successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

function* editLandDistance(value) {
  let id = value.id;
  let data = value.value;
  try {
    let params = {
      api: `${appConfig.ip}/mmtLandDist/` + id,
      method: "PUT",
      successAction: actionTypes.EDIT_LANDDISTANCE_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_LANDDISTANCE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_LANDDISTANCE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Land distance updated successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

function* deleteLandDistance(value) {
  let id = value.data.id;

  let data = {
    meansOfTransport: value.data.meansOfTransport,
    origin: value.data.origin,
    destination: value.data.destination,
    distance: value.data.distance,
    distanceFerry: value.data.distanceFerry,
    isActive: false,
  };

  try {
    let params = {
      api: `${appConfig.ip}/mmtLandDist/` + id,
      method: "PUT",
      successAction: actionTypes.DELETE_LANDDISTANCE_SUCCESS_ACTION,
      failAction: actionTypes.DELETE_LANDDISTANCE_FAIL_ACTION,
      extraAction: actionTypes.FETCH_LANDDISTANCE_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield toast.success("Land distance deleted successfully", {
      autoClose: 3000,
    });
    yield call(commonFunction, params);
  } catch (error) {
    console.error(error);
  }
}

export function* LandDistanceActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_LANDDISTANCE_INIT_ACTION,
    fetchLandDistance
  );
  yield takeEvery(actionTypes.ADD_LANDDISTANCE_INIT_ACTION, addLandDistance);
  yield takeEvery(actionTypes.EDIT_LANDDISTANCE_INIT_ACTION, editLandDistance);
  yield takeEvery(
    actionTypes.DELETE_LANDDISTANCE_INIT_ACTION,
    deleteLandDistance
  );
}
