export const FETCH_CORRESPONDANCEZONE_INIT_ACTION =
  "FETCH_CORRESPONDANCEZONE_INIT_ACTION";
export const FETCH_CORRESPONDANCEZONE_SUCCESS_ACTION =
  FETCH_CORRESPONDANCEZONE_INIT_ACTION + "_SUCCESS";
export const FETCH_CORRESPONDANCEZONE_FAIL_ACTION =
  FETCH_CORRESPONDANCEZONE_INIT_ACTION + "_ERROR";

export const ADD_CORRESPONDANCEZONE_INIT_ACTION =
  "ADD_CORRESPONDANCEZONE_INIT_ACTION";
export const ADD_CORRESPONDANCEZONE_SUCCESS_ACTION =
  ADD_CORRESPONDANCEZONE_INIT_ACTION + "_SUCCESS";
export const ADD_CORRESPONDANCEZONE_FAIL_ACTION =
  ADD_CORRESPONDANCEZONE_INIT_ACTION + "_ERROR";

export const EDIT_CORRESPONDANCEZONE_INIT_ACTION =
  "EDIT_CORRESPONDANCEZONE_INIT_ACTION";
export const EDIT_CORRESPONDANCEZONE_SUCCESS_ACTION =
  EDIT_CORRESPONDANCEZONE_INIT_ACTION + "_SUCCESS";
export const EDIT_CORRESPONDANCEZONE_FAIL_ACTION =
  EDIT_CORRESPONDANCEZONE_INIT_ACTION + "_ERROR";

export const DELETE_CORRESPONDANCEZONE_INIT_ACTION =
  "DELETE_CORRESPONDANCEZONE_INIT_ACTION";
export const DELETE_CORRESPONDANCEZONE_SUCCESS_ACTION =
  DELETE_CORRESPONDANCEZONE_INIT_ACTION + "_SUCCESS";
export const DELETE_CORRESPONDANCEZONE_FAIL_ACTION =
  DELETE_CORRESPONDANCEZONE_INIT_ACTION + "_ERROR";
