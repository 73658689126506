export const FETCH_PROJECTLIST_INIT_ACTION = "FETCH_PROJECTLIST_INIT_ACTION";
export const FETCH_PROJECTLIST_SUCCESS_ACTION =
  FETCH_PROJECTLIST_INIT_ACTION + "_SUCCESS";
export const FETCH_PROJECTLIST_FAIL_ACTION =
  FETCH_PROJECTLIST_INIT_ACTION + "_ERROR";

export const CHANGE_STATUS_PROJECT_INIT_ACTION =
  "CHANGE_STATUS_PROJECT_INIT_ACTION";
export const CHANGE_STATUS_PROJECT_SUCCESS_ACTION =
  CHANGE_STATUS_PROJECT_INIT_ACTION + "_SUCCESS";
export const CHANGE_STATUS_PROJECT_FAIL_ACTION =
  CHANGE_STATUS_PROJECT_INIT_ACTION + "_ERROR";

export const DELETE_PROJECT_INIT_ACTION = "DELETE_PROJECT_INIT_ACTION";
export const DELETE_PROJECT_SUCCESS_ACTION =
  DELETE_PROJECT_INIT_ACTION + "_SUCCESS";
export const DELETE_PROJECT_FAIL_ACTION = DELETE_PROJECT_INIT_ACTION + "_ERROR";

export const SHARE_PROJECT_INIT_ACTION = "SHARE_PROJECT_INIT_ACTION";
export const SHARE_PROJECT_FAIL_ACTION = SHARE_PROJECT_INIT_ACTION + "_ERROR";
export const SHARE_PROJECT_SUCCESS_ACTION =
  SHARE_PROJECT_INIT_ACTION + "SUCCESS";
