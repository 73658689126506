import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value, toastSuccess = false, toastError = true) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Bad Request - Field must not be empty";
    } else if (error.status === 401) {
      message = "Undefined";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status === 404) {
      message = "Not Found";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(`Error: ${message}`, { autoClose: 3000 });
    }

    throw error;
  }
}

function* fetchCO2Simulator(value) {
  console.log("value================>", value);
  try {
    let page = (value.value && value.value.page) || 1;
    let searchVal = (value?.value?.searchVal && value?.value?.searchVal) || "";
    let limit = (value?.value?.limit && value?.value?.limit) || 10;
    let params = {
      page: page,
      api: `${appConfig.ip}/getproductSwitchgear?limit=${limit}&page=${page}&q=${searchVal}`,
      method: "GET",
      successAction: actionTypes.FETCH_CO2SIMULATOR_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_CO2SIMULATOR_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

export function* CO2SimulatorActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_CO2SIMULATOR_INIT_ACTION,
    fetchCO2Simulator
  );
}
