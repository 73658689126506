export const ADD_PROJECT_DATA_INIT_ACTION = "ADD_PROJECT_DATA_INIT_ACTION";
export const ADD_PROJECT_DATA_SUCCESS_ACTION =
  ADD_PROJECT_DATA_INIT_ACTION + "_SUCCESS";
export const ADD_PROJECT_DATA_FAIL_ACTION =
  ADD_PROJECT_DATA_INIT_ACTION + "_ERROR";

export const ADD_PROJECTS_INIT_ACTION = "ADD_PROJECTS_INIT_ACTION";
export const ADD_PROJECTS_SUCCESS_ACTION =
  ADD_PROJECTS_INIT_ACTION + "_SUCCESS";
export const ADD_PROJECTS_FAIL_ACTION = ADD_PROJECTS_INIT_ACTION + "_ERROR";

export const UPDATE_PROJECTS_DATA_INIT_ACTION =
  "UPDATE_PROJECTS_DATA_INIT_ACTION";
export const UPDATE_PROJECTS_DATA_SUCCESS_ACTION =
  UPDATE_PROJECTS_DATA_INIT_ACTION + "_SUCCESS";
export const UPDATE_PROJECTS_DATA_FAIL_ACTION =
  UPDATE_PROJECTS_DATA_INIT_ACTION + "_ERROR";

export const FETCH_PROJECTBYID_INIT_ACTION = "FETCH_PROJECTBYID_INIT_ACTION";
export const FETCH_PROJECTBYID_SUCCESS_ACTION =
  FETCH_PROJECTBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_PROJECTBYID_FAIL_ACTION =
  FETCH_PROJECTBYID_INIT_ACTION + "_ERROR";

export const DOWNLOAD_REPORT_INIT_ACTION = "DOWNLOAD_REPORT_INIT_ACTION";
export const DOWNLOAD_REPORT_SUCCESS_ACTION =
  DOWNLOAD_REPORT_INIT_ACTION + "_SUCCESS";
export const DOWNLOAD_REPORT_FAIL_ACTION =
  DOWNLOAD_REPORT_INIT_ACTION + "_ERROR";

export const DOWNLOAD_REVISION_REPORT_INIT_ACTION =
  "DOWNLOAD_REVISION_REPORT_INIT_ACTION";
export const DOWNLOAD_REVISION_REPORT_SUCCESS_ACTION =
  DOWNLOAD_REVISION_REPORT_INIT_ACTION + "_SUCCESS";
export const DOWNLOAD_REVISION_REPORT_FAIL_ACTION =
  DOWNLOAD_REVISION_REPORT_INIT_ACTION + "_ERROR";

export const FETCH_EF_BY_NO_INIT_ACTION = "FETCH_EF_BY_NO_INIT_ACTION";
export const FETCH_EF_BY_NO_SUCCESS_ACTION =
  FETCH_EF_BY_NO_INIT_ACTION + "_SUCCESS";
export const FETCH_EF_BY_NO_FAIL_ACTION = FETCH_EF_BY_NO_INIT_ACTION + "_ERROR";

export const REMOVE_EF_BY_NO = "REMOVE_EF_BY_NO";

export const FETCH_REVISIONBYID_INIT_ACTION = "FETCH_REVISIONBYID_INIT_ACTION";
export const FETCH_REVISIONBYID_SUCCESS_ACTION =
  FETCH_REVISIONBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_REVISIONBYID_FAIL_ACTION =
  FETCH_REVISIONBYID_INIT_ACTION + "_ERROR";

export const ADD_REVISION_INIT_ACTION = "ADD_REVISION_INIT_ACTION";
export const ADD_REVISION_FAIL_ACTION = ADD_REVISION_INIT_ACTION + "_ERROR";
export const ADD_REVISION_SUCCESS_ACTION =
  ADD_REVISION_INIT_ACTION + "_SUCCESS";

export const FETCH_REVISIONLIST_INIT_ACTION = "FETCH_REVISIONLIST_INIT_ACTION";
export const FETCH_REVISIONLIST_FAIL_ACTION =
  FETCH_REVISIONLIST_INIT_ACTION + "_ERROR";
export const FETCH_REVISIONLIST_SUCCESS_ACTION =
  FETCH_REVISIONLIST_INIT_ACTION + "_SUCCESS";
