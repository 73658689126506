import React, { useState } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./termcodition.css"
const TermsAndCondition = (props) => {
    return (
        <div>
            <div>
                <h5 className="termHead1" >
                    Terms of use
                </h5>
            </div>
            <div className="contntDiv1" >
                <h2 className="termHead2" >Schneider Electric Terms of use</h2>
                <label className="lblcmn">
                    When you connect to or access this website, you agree to be bound by the general terms of use set out below,
                    as well as by the specific terms mentioned on certain pages of this site, as updated from time to time.
                    If you do not agree to these terms, you should leave this site immediately.
                </label>
                <br></br>
                <label className="lblcmn">
                    Unless otherwise specified, this website, its content, and its domain name and urls:
                    <a className="link1" > http://www.schneider-electric.com</a>, are the sole property of:
                </label>


                <div>
                    <h4 className="subHead1">Schneider Electric SE</h4>
                    <label className="lblcmn lblgrp1">
                        35, rue Joseph Monier
                    </label>
                    <label className="lblcmn lblgrp1">92500 Rueil Malmaison - France </label>
                    <label className="lblcmn lblgrp1">Legal form: European Company (joint-stock corporation) with a Board of Directors  </label>
                    <label className="lblcmn lblgrp1">Head office:</label>
                </div>

                <div>
                    <h4 className="subHead1">Managing Editor</h4>
                    <label className="lblcmn lblgrp1">
                        Chris Leong
                    </label>
                    <label className="lblcmn lblgrp1">Chief Marketing Officer  </label>
                    <label className="lblcmn lblgrp1">Schneider Electric Industries SAS  </label>
                    <label className="lblcmn lblgrp1">35, rue Joseph Monier </label>
                    <label className="lblcmn lblgrp1">92500 Rueil Malmaison - France </label>
                </div>

                <div>
                    <h4 className="subHead1">Host</h4>
                    <label className="lblcmn lblgrp1">
                        Schneider Electric SE
                    </label>
                    <label className="lblcmn lblgrp1">35 rue Joseph Monier  </label>
                    <label className="lblcmn lblgrp1">92500 Rueil Malmaison - France </label>
                    <label className="lblcmn lblgrp1">Phone: +33 (0) 1 41 29 70 00  </label>
                    <label className="lblcmn lblgrp1">Fax: +33 (0) 1 41 29 71 00 </label>
                </div>

                <div>
                    <h4 className="subHead2">1. Purpose of this web site:</h4>
                    <label className="lblcmn lblgrp1">
                        This website, as well as the contents including all
                        information, text, graphics, images, hyperlinks and other material are for
                        general information purpose about Schneider Electric.
                    </label>
                </div>

                <div>
                    <h4 className="subHead2">2. Trademark and copyright information</h4>
                    <label className="lblcmn lblgrp1">
                        The Schneider Electric brand and any registered trademarks of
                        Schneider Electric Industries SAS referred to on this website are the sole property of Schneider
                        Electric SE and its subsidiaries. They may not be used for any purpose without
                        the owner's permission, given in writing. This website and its content are
                        protected, within the meaning of the French intellectual property
                        code (Code de la propriété intellectuelle français, referred to hereafter as "the Code"),
                        under the laws of copyright covering texts, drawings and models, as well as by
                        trademark law. You agree not to reproduce, other than for your own personal,
                        non-commercial use as defined in the Code, all or part of this website or its
                        content on any medium whatsoever without Schneider Electric's permission,
                        given in writing. You also agree not to establish any hypertext links to this
                        website or its content. Schneider Electric does not grant any right or license
                        for the personal and non-commercial use of the website or its content, except
                        for a non-exclusive license to consult it on an "as is" basis, at your own risk.
                        All other rights are reserved.
                        <br></br>
                        <br></br>
                        Schneider Electric makes no claim or representations regarding the third party trademarks, which are the property of their respective owners.
                        <br></br>
                        <br></br>

                        For more information, contact the webmaster at:
                        <br></br>
                        <a className="link1 linkalgn1" >FR-webmaster-info@fr.schneider-electric.com</a>
                    </label>
                </div>

                <div>
                    <h4 className="subHead2">3. Links to other web sites</h4>
                    <label className="lblcmn lblgrp1">
                    This web site may contain links to other web sites, which are provided 
                    solely as convenience to you and not as an endorsement by Schneider 
                    Electric of the contents of such other web sites . Schneider Electric is not 
                    responsible for the content of any other web sites and does not make any 
                    representations or warranties regarding any other web sites or the contents or 
                    materials of such web sites. 
                    </label>
                </div>

                <div>
                    <h4 className="subHead2">4. Updating of information </h4>
                    <label className="lblcmn lblgrp1">
                    Schneider Electric makes all reasonable efforts to ensure that this website is 
                    accessible at all times. You may however be prevented from accessing the site or 
                    experience access problems due to technical difficulties or during maintenance 
                    work or upgrades. In addition, while Schneider Electric takes reasonable care to 
                    ensure that the information on this site is kept up to date, it makes no 
                    representation or warranty, 
                    express or implied, of its accuracy and completeness. 
                    <br></br>
                    <br></br>
                    The content of this website and the websites accessible via hypertext 
                    links at this site may be corrected, amended or updated at any time 
                    without notice. 
                    <br></br>
                    <br></br>
                    Schneider Electric does not guarantee and makes no representations or 
                    warranties that this website and access thereto are secure, or that 
                    this website is free of viruses or bugs that could result in damage 
                    to or the loss of data. You are responsible for protecting your data 
                    from viruses. 
                    <br></br>
                    <br></br>
                    Schneider Electric accepts no liability for any direct, special, indirect or 
                    consequential damages of whatsoever kind and howsoever caused, arising out of 
                    your use of or inability to access the website. 
                    </label>
                </div>

                <div>
                    <h4 className="subHead2">5. Financial and share price information</h4>
                    <label className="lblcmn lblgrp1">
                    The information provided through this website is not intended to be 
                    and shall not be deemed to be an offer, invitation or inducement to 
                    invest in or otherwise deal in Schneider Electric SE shares or any 
                    other financial instruments whatsoever issued by any Schneider 
                    Electric company, nor to provide or constitute any advice or 
                    recommendation in connection with any investment decision. 
                    <br></br>
                    <br></br>
                    The share price published on the Finance page of this website is supplied 
                    by an outside company and Schneider Electric accepts no liability 
                    whatsoever in relation to any delay or inaccuracies in the information 
                    supplied via this link, over which it has no control. This website may 
                    also provide links to other external websites that are not controlled by 
                    Schneider Electric. Schneider Electric is not responsible for the content 
                    of any such websites.
                    </label>
                </div>

                <div>
                    <h4 className="subHead2">6. LIMITATION OF LIABILITY</h4>
                    <label className="lblcmn lbltxt2">
                    IN NO EVENT WILL SCHNEIDER ELECTRIC BE LIABLE TO ANY PARTY FOR ANY 
                    DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL 
                    DAMAGES OF ANY TYPE WHATSOEVER RELATED TO OR ARISING FROM THIS WEB 
                    SITE OR ANY USE OF THIS WEB SITE, OR OF ANY SITE OR RESOURCE LINKED 
                    TO, REFERENCED, OR ACCESSED THROUGH THIS WEB SITE, OR FOR THE USE OR 
                    DOWNLOADING OF, OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, 
                    OR SERVICES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, 
                    BUSINESS INTERRUPTION, LOST SAVINGS OR LOSS OF PROGRAMS OR OTHER 
                    DATA, EVEN IF SCHNEIDER ELECTRIC IS EXPRESSLY ADVISED OF THE 
                    POSSIBILITY OF SUCH DAMAGES. THIS EXCLUSION AND WAIVER OF LIABILITY 
                    APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED ON CONTRACT, WARRANTY, 
                    TORT, OR ANY OTHER LEGAL THEORIES. 
                    </label>
                </div>

                <div>
                    <h4 className="subHead2">7. Confidential or proprietary information</h4>
                    <label className="lblcmn lbltxt2">
                    Schneider Electric does not want to receive confidential or 
                    proprietary information from you through our Web Site. Please note that any 
                    information or material sent to Schneider Electric will be deemed NOT to be 
                    confidential. By sending Schneider Electric any information or material, you grant 
                    Schneider Electric an unrestricted, irrevocable license to use, reproduce, display, 
                    perform, modify transmit and distribute those materials or information, and you also 
                    agree that Schneider Electric is free to use any ideas, concepts, know-how or 
                    techniques that you send us for any purpose.  
                    </label>
                </div>

                <div>
                    <h4 className="subHead2">8. Jurisdictional issues</h4>
                    <label className="lblcmn lbltxt2">
                    Unless otherwise specified, the materials in this Web site are presented solely 
                    for the purpose of promoting Schneider Electric products. Schneider Electric makes no 
                    representation that materials or products depicted in the site are appropriate or 
                    available in all locations.  
                    <br></br>
                    These Terms and Conditions shall be governed by and construed in accordance with 
                    the laws of France without giving effect to any principles of conflicts of law. 
                    If any provision of this agreement shall be unlawful, void, or for any reason 
                    unenforceable, then that provision shall be deemed severable from these Terms and 
                    Conditions and shall not affect the validity and enforceability of any remaining 
                    provisions. This is the entire agreement between the parties relating to the subject 
                    matter 
                    herein and shall not be modified except in writing, signed by both parties. 
                    </label>
                </div>

            </div>
        </div>
    )
}
export default TermsAndCondition;