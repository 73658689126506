import * as actionTypes from "./constants";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value, toastSuccess = false, toastError = true) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  let headers = value.formData
    ? { Authorization: `Bearer ${Psbtoken}` }
    : {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      };
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: headers,
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${Psbtoken}`,
      // },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Bad Request - Field must not be empty";
    } else if (error.status === 401) {
      message = "Undefined";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status === 404) {
      message = "Not Found";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(`Error: ${message}`, { autoClose: 3000 });
    }

    throw error;
  }
}

function* fetchWordDoc() {
  // let searchVal =
  //   value && value.value && value.value.searchVal ? value.value.searchVal : "";
  try {
    // let page = (value.value && value.value.page) || 1;
    let params = {
      //  page: page,
      api: `${appConfig.ip}/msWord`,
      method: "GET",
      successAction: actionTypes.FETCH_WORD_DOC_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_WORD_DOC_FAIL_ACTION,
      formData: false,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* addWordDocx(action) {
  var formdata = new FormData();
  formdata.append("file", action.payload.docFile, action.payload.docFile.name);
  formdata.append("version", "1");

  // let data = new FormData();
  // // data.append('name', 'files')
  // // data.append("files", value, value.name);

  // data.append("file", action.payload.file, action.payload.file.name);
  // data.append("version", action.payload.version);
  // console.log("======action=======", data);
  // const data = {
  //   name: action.payload.version,
  //   desc: value.value.desc,
  // };
  try {
    let params = {
      api: `${appConfig.ip}/msWord`,
      method: "POST",
      successAction: actionTypes.ADD_WORD_DOC_SUCCESS_ACTION,
      failAction: actionTypes.ADD_WORD_DOC_FAIL_ACTION,
      extraAction: actionTypes.FETCH_WORD_DOC_INIT_ACTION,
      body: formdata,
      formData: true,
    };
    yield call(commonFunction, params, true);
    yield toast.success("Word Document added successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

export function* MsWordActionWatcher() {
  yield takeEvery(actionTypes.FETCH_WORD_DOC_INIT_ACTION, fetchWordDoc);
  yield takeEvery(actionTypes.ADD_WORD_DOC_INIT_ACTION, addWordDocx);
}
