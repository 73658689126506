import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
  selectedTab: "sendOtp",
};

export default function forgotPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FORGOT_SEND_OTP_PASSWORD_SUCCESS_ACTION:
      return {
        ...state,
        otpSent: action.payload.ok,
        selectedTab: "verifyOtp",
      };

    case actionTypes.OTP_VERIFICATION_SUCCESS_ACTION:
      if (action.payload.ok === true) {
        return {
          ...state,
          otpVerified: true,
          selectedTab: "resetPass",
        };
      } else {
        return {
          ...state,
        };
      }

    case actionTypes.REDIRECT_TO_LOGIN_SUCCESS_ACTION:
      return {
        ...state,
        otpSent: undefined,
        otpVerified: undefined,
        selectedTab: "sendOtp",
      };

    case actionTypes.RESET_NEW_PASSWORD_SUCCESS_ACTION:
      return {
        ...state,

        resetPswd: true,
        otpVerified: undefined,
        otpSent: undefined,
        selectedTab: "sendOtp",
      };
    default:
      return {
        ...state,
      };
  }
}
